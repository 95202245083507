import { CommonModule } from '@angular/common';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  NbAuthJWTToken,
  NbAuthModule,
  NbOAuth2AuthStrategy,
  NbOAuth2ResponseType,
  NbPasswordAuthStrategy,
} from '@nebular/auth';

import { environment } from 'environments/environment';
import { throwIfAlreadyLoaded } from './module-import-guard';

export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: environment.backendUrl,
        login: {
          endpoint: '/login/',
          redirect: {
            success: null,
          },
        },

        logout: {
          endpoint: '',
        },
        refreshToken: {
          endpoint: '/refresh/',
        },
        errors: {
          key: 'data.errors',
        },

        token: {
          class: NbAuthJWTToken,
          key: 'token',
        },
      }),
      NbOAuth2AuthStrategy.setup({
        name: 'google',
        clientId:
          '890966179917-4r6kjlu9il4jobah1rs5b8li9sgdvqtv.apps.googleusercontent.com',
        clientSecret: '',
        authorize: {
          endpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
          responseType: NbOAuth2ResponseType.TOKEN,
          scope: 'https://www.googleapis.com/auth/userinfo.profile',
          redirectUri: environment.backendUrl + '/login/',
        },
      }),
    ],
    forms: {},
  }).providers,
];

@NgModule({
  imports: [CommonModule],
  exports: [NbAuthModule],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS],
    };
  }
}
