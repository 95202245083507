<nb-card>
  <nb-card-header class="apart-row">
    Choose a customer to impersonate
    <button
      nbButton
      [disabled]="(impersonating$ | async) === null"
      (click)="impersonateCustomer(null)"
      style="margin-left: 25px"
    >
      stop impersonating
    </button></nb-card-header
  >
  <nb-card-body>
    <resplendent-autocomplete
      [formControl_]="formControl"
      [possibleValues]="impersonatingOptions$ | async"
      [aliasMap]="impersonationMap$ | async"
    ></resplendent-autocomplete>
  </nb-card-body>
</nb-card>
