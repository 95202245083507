import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'resplendent-forced-feature-item-selector-dialog',
  templateUrl: './forced-feature-item-selector-dialog.component.html',
  styleUrls: ['./forced-feature-item-selector-dialog.component.scss'],
})
export class ForcedFeatureItemSelectorDialogComponent implements OnInit {
  public tier;
  public maxDashboards;
  public maxWidgets;
  public maxUsers;

  public isTrialExpDowngrade;

  constructor(
    private state: StateService,
    protected dialogRef: NbDialogRef<ForcedFeatureItemSelectorDialogComponent>,
  ) {}

  ngOnInit(): void {
    const tier = this.state.sessionVariables.user.tier;
    const tierInfo = this.state.sessionVariables.tierInfo?.tiers[tier];
    if (!tierInfo) {
      return;
    }
    this.tier = tier;
    this.maxDashboards = tierInfo.dashboards > 1000000 ? 'unlimited' : tierInfo.dashboards;
    this.maxWidgets = tierInfo.widgets > 1000000 ? 'unlimited' : tierInfo.widgets;
    this.maxUsers = tierInfo.users > 1000000 ? 'unlimited' : tierInfo.users;

    const trialExp = this.state.sessionVariables.user.trial_expiration;
    const now = Date.now();
    this.isTrialExpDowngrade = trialExp < now;
  }

  public saveHandler(e) {
    this.dialogRef.close();
  }

  public close() {
    this.dialogRef.close();
  }
}
