import { IAppState } from '@interfaces';
import { interval, map, tap } from 'rxjs';

export const initialAppState: () => IAppState = () => {
  return {
    currentDashboard: undefined,
    allDashboards: {
      isLoading: false,
      dashboards: [],
    },
    widgets: {},
    calculations: {},
    widgetEditor: {
      isLoading: false,
      isOpen: false,
    },
    dataStreamsState: { dataStreams: {}, isLoading: false },
    dataSourcesState: { dataSources: [], isLoading: false },
    templates: [],
    tagOptions: { isLoading: false, tags: [] },
    soundboard: { isLoading: false, sounds: [] },
    filterVariableState: {
      isLoading: false,
      filterVariables: [],
      currentFilterVariableState: null,
    },
    filterViews: {},
    shareLinkState: { isLoading: false, shareLinks: [] },
    impersonationOptions: [],
    impersonatingCustomerId:
      localStorage.getItem('impersonatingCustomerId') === 'null'
        ? null
        : localStorage.getItem('impersonatingCustomerId') || null,
    frontEndState: {
      fullScreen: true,
      shareToken: null,
    },
    fullModalData: {},
    dashboardGroups: [],
    dashboardFolders: [],
  };
};

export interface IAppStateModel {
  appState: IAppState;
}
