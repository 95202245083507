<div *ngIf="!minimized; else minimizedUpdate" class="change-log-dialog">
  <nb-card>
    <nb-card-header>
      <button nbButton nbTooltip="Minimize" (click)="minimized = true" size="small">
        <nb-icon icon="diagonal-arrow-left-down-outline"></nb-icon>
      </button>
      <div>
        <h3>ᕙ⁠(⁠⇀⁠‸⁠↼⁠‶⁠)⁠ᕗ</h3>
      </div>
    </nb-card-header>
    <nb-card-body>
      <h6>New Update Available</h6>
      <p>
        Our digital elves have been toiling away, inventing shiny new features and squashing those pesky bugs. Time to
        hit that refresh button and unveil the latest, greatest version of our app. Enjoy the magic!
      </p>
      <p>
        Read more about it over in our
        <a href="https://www.resplendentdata.com/blog" target="_blank"
          >blog<nb-icon icon="external-link-outline"></nb-icon
        ></a>
      </p>
      <br />
      <p>Don't want to update yet, click the minimize button above and we'll let you get back to your dashboards.</p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="success" hero (click)="updateVersion()" size="small">
        Refresh <nb-icon icon="refresh-outline"></nb-icon>
      </button>
    </nb-card-footer>
  </nb-card>
</div>

<ng-template #minimizedUpdate>
  <nb-card class="update-dialog">
    <nb-card-body>
      <div class="row align-middle">
        <div class="col-auto">
          <img src="/assets/icons/icon-72x72.png" alt="" />
        </div>
        <div class="col-auto">
          <div class="update-message">
            <div class="message-text">We have updates for you</div>
            <div class="btn-link" (click)="updateVersion()">Refresh to Update</div>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
