import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class UserFeedbackService {
  constructor() {}

  submitFeedback(feedback: string, name: string, email: string) {
    const eventId = Sentry.captureMessage('User Feedback');
    console.log(`Feedback sent (event id: ${eventId}): ${feedback}`);
    const userFeedback = {
      event_id: eventId,
      name: name,
      email: email,
      comments: feedback,
    };
    Sentry.captureUserFeedback(userFeedback);
  }
}
