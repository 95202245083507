import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ComponentCanDeactivate } from './component-can-deactivate';

/**
 * This guard warns the user when he tries to leave the page and has unsaved changes. There's no easy way to do this
 * so here's the instruction:
 *
 * 1. Add the canDeactivateGuard in the component route
 * `{
 *   path: 'your/path',
 *   component: YourComponent,
 *   canDeactivate: [CanDeactivateGuard]
 * }`
 *
 * 2. Extend the component in question from `FormCanDeactivateComponent`. This step sucks due to BaseComponent already being
 *    there, but you're a big boy, you can implement isDestroyed$ locally :)
 *
 * 3. Add a method in that component called `confirmLeaveForm()`. This method needs to return an object containing the
 *    data used to decide whether or not the user can leave the page freely. You have 2 ways of doing this:
 *    - Return either `ICanDeactivateManualControl` OR `Promise<ICanDeactivateManualControl>`. Set the values on this
 *      object manually however you like. You have full manual control in this case.
 *
 *    - Return an `NgForm` object, this is a simpler way to do it for simple forms. Here's the needed lines:
 *      - Template:
 *        `<form #myFormName="ngForm">...</form> `
 *      - TS:
 *        `@ViewChild ('myFormName') myForm: NgForm
 *         confirmLeaveForm() { return this.myForm }`
 */

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  async canDeactivate(component: ComponentCanDeactivate): Promise<boolean> {
    if (!component.canDeactivate)
      throw new Error(
        "Can deactivate method not implemented. Most likely you added the CanDeactivateGuard but didn't extend FormCanDeactivateComponent for the component you want to guard.",
      );
    const canDeactivate = await component.canDeactivate();
    if (!canDeactivate) {
      if (confirm('You have unsaved changes! If you leave, your changes will be lost.')) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
