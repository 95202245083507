import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { App } from '@root/state/app.actions';
import { StateService } from '@services';
import { lastValueFrom, take, takeUntil, tap } from 'rxjs';
import { BaseComponent } from '../base-component/base.component';

type RedirectType =
  | 'dashList'
  | 'recentDashboard'
  | 'integrations'
  | 'datasets';

/**
 * This component acts kind of like a guard. If the user navigates to the root url with the
 * query param "redirectType", this will tri
 */
@Component({
  selector: 'resplendent-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent extends BaseComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private stateService: StateService,
    private store: Store,
    private actions$: Actions,
    private authService: NbAuthService,
  ) {
    super();
  }

  ngOnInit() {
    this.detectRedirectParam();
  }

  private detectRedirectParam() {
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.isDestroyed$),
        tap((params) => {
          this.pickRedirectAction(params);
        }),
      )
      .subscribe();
  }

  private async pickRedirectAction(params: Params) {
    console.log('params', params);
    this.startLoading();
    let redirectType: RedirectType = params.redirectType;
    const companyVersion =
      this.stateService.sessionVariables?.user?.app_version;
    let recentDashboard: string;
    if (!redirectType) {
      const isLoggedIn = await lastValueFrom(
        await this.authService.isAuthenticated(),
      );
      if (!isLoggedIn) {
        this.router.navigate(['/auth/sign-in'], {
          queryParams: { redirectType },
        });
        return;
      }
      // load dashboards to check if the user has any
      await this.stateService.loadUserPreferences();
      recentDashboard = this.stateService.sessionVariables?.RecentDashboards[0];
      if (recentDashboard) {
        redirectType = 'recentDashboard';
      } else {
        // Load integrations to check if the user has any
        this.store.dispatch(new App.FetchDataSources());
        const integrations: App.FetchDataSourcesSuccess = await lastValueFrom(
          this.actions$.pipe(
            ofActionSuccessful(App.FetchDataSourcesSuccess),
            take(1),
          ),
        );
        const hasConnections = integrations.dataSources.some(
          (ds) => ds.connections.length > 0,
        );
        // if the user has integrations, redirect to the data streams page
        if (!hasConnections) {
          redirectType = 'integrations';
        } else {
          this.store.dispatch(new App.FetchDataStreams(false));
          const dataStreams: App.FetchDataStreamsSuccess = await lastValueFrom(
            this.actions$.pipe(
              ofActionSuccessful(App.FetchDataStreamsSuccess),
              take(1),
            ),
          );
          const hasDataStreams =
            Object.keys(dataStreams.dataStreams).length > 0;
          if (!hasDataStreams) {
            redirectType = 'datasets';
          } else {
            redirectType = 'dashList';
          }
        }
      }
    }
    try {
      switch (redirectType) {
        case 'recentDashboard':
          this.router.navigate(['dash-v2', recentDashboard]);

          break;
        case 'dashList':
          this.router.navigate(['/dashboards']);
          break;
        case 'integrations':
          this.router.navigate(['/data-management/integrations']);
          break;
        case 'datasets':
          this.router.navigate(['/data-management/datasets']);
          break;
        default:
          throw new Error(`Unknown redirectType "${redirectType}"`);
      }
    } catch (e) {
      this.stopLoading();
      throw e;
    }
  }

  private assertToken(params: Params) {
    if (typeof params.token == 'string') {
      return true;
    } else {
      throw new Error(`Missing token on request "${params.redirectType}"`);
    }
  }
}
