import { Component } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';

@Component({
  selector: 'resplendent-update-dialog',
  templateUrl: './update-dialog.component.html',
  styleUrls: ['./update-dialog.component.scss'],
})
export class UpdateDialogComponent {
  minimized = false;
  constructor(private dialog: NbDialogService, private dialogRef: NbDialogRef<UpdateDialogComponent>) {}

  updateVersion() {
    window.location.reload();
  }
}
