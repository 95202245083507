import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboards',
    home: true,
    // generate 5 sub items with 'Loading...' as title
    children: [
      {
        title: 'All Dashboards',
        icon: 'grid-outline',
        link: '/dashboards',
      },
      {
        title: 'Share Links',
        icon: 'share-outline',
        link: '/share-links',
      },
      ...Array.from({ length: 5 }, (_, i) => ({
        title: 'Loading...',
      })),
    ],
  },
  {
    title: 'Data Settings',
    children: [
      {
        title: 'Integrations',
        icon: 'cloud-download-outline',
        link: '/data-management/integrations',
      },
      {
        title: 'Datasets',
        icon: 'archive-outline',
        link: '/data-management/datasets',
      },
      {
        title: 'Modified Datasets',
        icon: 'cube-outline',
        link: '/data-management/modified-datasets',
      },
      {
        title: 'Filter Variables',
        icon: { icon: 'filter-variable', pack: 'resplendent' },
        link: '/dynamic-filter-variables',
      },
      {
        title: 'Sync Agents',
        icon: 'refresh-outline',
        link: '/sync-agents',
      },
      {
        title: 'Template Gallery',
        icon: 'shopping-cart-outline',
        link: '/template-gallery',
      },
    ],
  },
  {
    title: 'Company',
    children: [
      {
        title: 'Users',
        icon: 'people-outline',
        link: '/users',
      },
      {
        title: 'Subscription',
        icon: 'credit-card-outline',
        link: '/subscription',
      },
      {
        title: 'Dashboard Groups',
        icon: 'folder-outline',
        link: '/manage-dashboard-groups',
      },
      {
        title: 'Tier Features',
        icon: 'toggle-right-outline',
        link: '/tier-features',
      },
      {
        title: 'Audit Log',
        icon: 'list-outline',
        link: '/audit-log',
      },
    ],
  },
  {
    title: 'Reseller Portal',
    icon: 'shopping-bag-outline',
    // This link string is used to identify this item. If you change it do a global search first to resolve dependencies
    link: '/reseller-portal',
    children: [
      {
        title: 'Clients',
        icon: 'people-outline',
        link: '/reseller-portal/clients',
      },
      {
        title: 'Users',
        icon: 'poo',
        link: '/reseller-portal/users',
      },
      {
        title: 'Stripe',
        icon: 'people-outline',
        link: '/reseller-portal/stripe',
      },
    ],
  },
  {
    title: 'Additional Settings',
    children: [
      // {
      //   title: 'Resplendent API',
      //   icon: 'code-outline',
      //   link: '/resplendent-api',
      // },
      {
        title: 'Manage Tags',
        icon: 'pricetags-outline',
        link: '/manage-tags',
      },
      {
        title: 'Manage Soundboard',
        icon: 'volume-up-outline',
        link: '/manage-soundboard',
      },
    ],
  },
];
