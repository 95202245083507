import { Injectable } from '@angular/core';
import { NavigationCancel, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class AdminGuard {
  guardedURL: string = undefined;

  constructor(private authService: NbAuthService, private router: Router) {
    router.events.pipe(filter((event) => event instanceof NavigationCancel)).subscribe((event: NavigationCancel) => {
      this.guardedURL = event.url;
    });
  }

  canActivate() {
    return this.authService.getToken().pipe(
      map((token) => {
        const tokenObj = token.getPayload();
        return tokenObj.role === 'admin';
      }),
    );
  }
}
