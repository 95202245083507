import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbContextMenuModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbMenuModule,
  NbPopoverModule,
  NbSidebarModule,
  NbUserModule,
} from '@nebular/theme';
import { GeneralLayoutComponent } from './general-layout.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UtilitySidebarModule } from '../utility-sidebar/utility-sidebar.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ImpersonationDialogComponent } from './impersonation-dialog/impersonation-dialog.component';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    AutocompleteModule,
    NbLayoutModule,
    NbIconModule,
    NbActionsModule,
    NbUserModule,
    NbPopoverModule,
    NbContextMenuModule,
    NbSidebarModule,
    NbMenuModule,
    NbCardModule,
    NbButtonModule,
    NbInputModule,
    RouterModule,
    UtilitySidebarModule,
    ReactiveFormsModule,
  ],
  declarations: [GeneralLayoutComponent, ImpersonationDialogComponent],
  exports: [GeneralLayoutComponent],
})
export class GeneralLayoutModule {}
