// import { GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CanDeactivateGuard } from '@guards';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbButtonModule,
  NbCardModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
  NbToastrModule,
} from '@nebular/theme';
import { BaseComponent } from '@root/core-components/base-component';
// import { SentryErrorHandler } from 'main';
import { TimeagoModule } from 'ngx-timeago';
import { environment } from '../environments/environment';
import { CoreModule } from './@core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LegacyDashboardRedirectorComponent } from './core-components/legacy-dashboard-redirector/legacy-dashboard-redirector.component';
import { SocialLoginComponent } from './pages/oauth/social-login/social-login.component';

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { ForcedFeatureItemSelectorDialogComponent } from '@root/core-components/forced-feature-item-selector-dialog/forced-feature-item-selector-dialog.component';
import { UserActionDialogComponent } from '@root/core-components/user-action-dialog/user-action-dialog.component';

import { GeneralLayoutModule } from '@scams/components/general-layout/general-layout.module';
import { GraphAnimatedBarLoaderModule } from '@scams/components/graph-animated-bar-loader/graph-animated-bar-loader.module';

import { RedirectComponent } from './core-components/redirect/redirect.component';
import { UpdateDialogComponent } from './core-components/update-dialog/update-dialog.component';
import { WebsocketDisconnectOverlayComponent } from './core-components/websocket-disconnect-overlay/websocket-disconnect-overlay.component';
import { AdminGuard } from './guards/admin.guard';
import { FeatureItemSelectorModule } from './shared/feature-item-selector/feature-item-selector.module';
import { AppState } from './state/app.state';
import { MarkdownModule } from 'ngx-markdown';
import * as Sentry from '@sentry/angular';

let sentryProviders = [];
(function () {
  if (!environment.sentryDsn) return;
  Sentry.init({
    release: environment.sentryRelease,
    dsn: environment.sentryDsn,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tracePropagationTargets: [environment.appUrl],

    // NOTE this value makes 100% of the transactions get sent to sentry
    // for the current prod (Aug 2021) with few users it's fine to send
    // everything, however with greater usage this should be decreased
    tracesSampleRate: environment.production ? 0.3 : 0,
    // We set a static limit of errors per session using this callback
    beforeSend: (event, _) => {
      const { maxSentryErrors, sentSentryErrors } = environment;
      environment.sentSentryErrors++;
      if (sentSentryErrors > maxSentryErrors) {
        return null;
      }
      return event;
    },
  });
  sentryProviders = [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ];
})();

@NgModule({
  declarations: [
    AppComponent,
    RedirectComponent,
    BaseComponent,
    LegacyDashboardRedirectorComponent,
    SocialLoginComponent,
    WebsocketDisconnectOverlayComponent,
    UpdateDialogComponent,
    ForcedFeatureItemSelectorDialogComponent,
    UserActionDialogComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    NbMenuModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbToastrModule.forRoot(),
    NbDialogModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbThemeModule.forRoot({ name: 'dark' }),
    NbCardModule,
    NbButtonModule,
    NbIconModule,
    CoreModule.forRoot(),
    TimeagoModule.forRoot(),
    ServiceWorkerModule.register('custom-service-worker.js', {
      enabled: environment.production || environment.staging,
    }),
    NgxsModule.forRoot([AppState]),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    BrowserModule,
    BrowserAnimationsModule,
    NbEvaIconsModule,
    MatProgressBarModule,
    GeneralLayoutModule,
    GraphAnimatedBarLoaderModule,
    FeatureItemSelectorModule,
    MarkdownModule.forRoot(),
  ],
  providers: [
    Title,
    CanDeactivateGuard,
    AdminGuard,
    ...sentryProviders,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
