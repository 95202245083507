import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '@base-component';
import { Store } from '@ngxs/store';
import { App } from '@root/state/app.actions';
import { IAppStateModel } from '@root/state/app.model';
import { map, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'resplendent-impersonation-dialog',
  templateUrl: './impersonation-dialog.component.html',
  styleUrls: ['./impersonation-dialog.component.scss'],
})
export class ImpersonationDialogComponent extends BaseComponent implements OnInit {
  constructor(private store: Store) {
    super();
  }
  impersonating$ = this.store
    .select((state) => (state.app as IAppStateModel).appState.impersonatingCustomerId)
    .pipe(
      tap((val) => {
        if (this.formControl.value !== val) this.formControl.setValue(val);
      }),
    );
  impersonatingOptions$ = this.store
    .select((state) => (state.app as IAppStateModel).appState.impersonationOptions)
    .pipe(map((options) => options.map((opt) => opt.id)));
  impersonationMap$ = this.store
    .select((state) => (state.app as IAppStateModel).appState.impersonationOptions)
    .pipe(map((options) => options.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.name }), {} as Record<string, any>)));

  formControl = new FormControl<string>('');
  ngOnInit(): void {
    this.formControl.valueChanges.pipe(takeUntil(this.isDestroyed$)).subscribe((val) => {
      this.impersonateCustomer(val);
    });
  }
  impersonateCustomer(customerId: string) {
    this.store.dispatch(new App.SetImpersonationCustomerId(customerId, true));
  }
}
