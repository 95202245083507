import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  NavigationCancel,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { EventQueueService } from '../services/event-queue.service';
import { IUserTokenPayload } from '@interfaces';
import { sanitizeUrl } from '@helpers';

@Injectable()
export class AuthGuard {
  guardedURL: string = undefined;

  constructor(
    private authService: NbAuthService,
    private router: Router,
    private eventQueue: EventQueueService,
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationCancel))
      .subscribe((event: NavigationCancel) => {
        this.guardedURL = event.url;
      });
  }

  canActivate() {
    return this.authService.isAuthenticated().pipe(
      withLatestFrom(this.authService.getToken()),
      tap(([authenticated, token]) => {
        const hasToken = !!token && !!(token as any)?.token;
        if (!hasToken) {
          setTimeout(() => {
            this.router.navigate(['/auth/sign-in'], {
              queryParams: { comingFrom: sanitizeUrl(this.guardedURL) },
            });
          }, 50);
          return;
        }
        const isValid = token.isValid();
        if (!isValid) {
          this.eventQueue.dispatch('FORCE_SIGN_OUT', 'Your token is not valid');
          return false;
        }
        const isExpired = token.getPayload().exp < Date.now() / 1000;
        if (isExpired) {
          this.eventQueue.dispatch('FORCE_SIGN_OUT', 'Your token has expired');
          return false;
        }
        if (!authenticated) {
          setTimeout(() => {
            this.router.navigate(['/auth/sign-in'], {
              queryParams: { comingFrom: sanitizeUrl(this.guardedURL) },
            });
          }, 50);
          return false;
        }

        const payload: IUserTokenPayload = token.getPayload();
        // Check that the user has verified their email
        if (!payload.email_verified) {
          // If they haven't, redirect them to the email verification page
          this.router.navigate(['/auth/verify-email'], {
            queryParams: { comingFrom: sanitizeUrl(this.guardedURL) },
          });
          return false;
        }
        return true;
      }),
      map(([authenticated, _]) => authenticated),
    );
  }
}
