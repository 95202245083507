import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  IAddFVIWCResponse,
  IAllDashboardsState,
  IAPIResponse,
  IAppState,
  IBooleanMap,
  IColumnValues,
  ICopyDashboardV2Response,
  ICreateDashboardV2Response,
  ICreateFilterViewRequestPayload,
  IDashboardFilterVariableState,
  IDashV2State,
  IDataSourcesState,
  IDataStreamColumnMap,
  IDataStreamMap,
  IDataStreamState,
  IDeleteDashboardV2Response,
  IDeleteFVIWCResponse,
  IFetchFilterViewsResponse,
  IFilterVariableBackendState,
  IFilterVariableState,
  IFilterView,
  IFilterViewItem,
  IFilterViews,
  IFilterViewState,
  IFrontEndState,
  IGetCalculationDataRequestV2,
  IGetCalculationDataResponse,
  IGetCalculationPreviewsRequest,
  IGetCalculationPreviewsResponse,
  IGetDashboardV2Response,
  IGetFilterVariableStateResponse,
  IGetSharedDashboardV2Response,
  IRefreshCompanyDashboardResponse,
  IShareLinkState,
  ISoundboardState,
  IStorableWidgetV2,
  ITagOptionsState,
  IUpdateWidgetsPositionsRequestPayload,
  IWidgetEditorState,
  IWidgetStateMap,
} from '@interfaces';

import {
  Action,
  Actions,
  ofActionCompleted,
  ofActionSuccessful,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { WidgetEditorComponent } from '@scams/components/widget-editor/widget-editor.component';
import { EventQueueService, StateService, WebsocketService } from '@services';
import {
  catchError,
  debounceTime,
  first,
  map,
  Observable,
  Subject,
  take,
  tap,
} from 'rxjs';
import {
  App,
  Dash,
  FilterVariable,
  FilterViews,
  ShareLink,
  Widget,
  WidgetEditor,
} from './app.actions';
import { IAppStateModel, initialAppState } from './app.model';
import { WidgetStateObject } from './state-model-objects/widget.state-model';
import { NbSidebarService } from '@nebular/theme';
import { environment } from 'environments/environment';
import { Papa } from 'ngx-papaparse';

@State<IAppStateModel>({
  name: 'app',
  defaults: {
    appState: initialAppState(),
  },
})
@Injectable()
export class AppState {
  private getCalculationDataSource$: Subject<null> = new Subject();
  private getCalculationDataRequestBuffer: {
    req: IGetCalculationDataRequestV2;
    token: string;
    widgetIds: string[];
  }[] = [];
  private unsubscribeFromCalculationsSource$: Subject<null> = new Subject();
  private unsubscribeFromCalculationsRequestBuffer: {
    shareToken: string;
    calcIds: string[];
  }[] = [];
  private subscribeToCalculationsSource$: Subject<null> = new Subject();
  private subscribeToCalculationsRequestBuffer: {
    shareToken: string;
    calcIds: string[];
  }[] = [];

  constructor(
    private ws: WebsocketService,
    private eventQueue: EventQueueService,
    private store: Store,
    private actions$: Actions,
    private router: Router,
    private legacyGlobalState: StateService,
    private sidebarService: NbSidebarService,
    private papa: Papa,
  ) {
    this.getCalculationDataSource$
      .asObservable()
      .pipe(debounceTime(10))
      .subscribe(() => {
        this.processGetCalculationDataRequestBuffer();
      });
    this.unsubscribeFromCalculationsSource$
      .asObservable()
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.processUnsubscribeFromCalculationsRequestBuffer();
      });
    this.subscribeToCalculationsSource$
      .asObservable()
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.processSubscribeToCalculationsRequestBuffer();
      });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////                App                    /////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  @Action(App.FetchDataStreams) fetchDataStreams(
    ctx: StateContext<IAppStateModel>,
    action: App.FetchDataStreams,
  ) {
    const {
      fetchColumnInfo,
      widgetIds,
      refreshAllDataStreams,
      loadPossibleValues,
      shareToken,
    } = action;

    // Update the dataStreams state to be loading
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          dataStreamsState: patch<IDataStreamState>({
            isLoading: true,
          }),
        }),
      }),
    );

    // Signal to refresh the data stream state
    const refreshDataStreamState = refreshAllDataStreams;
    let reqObservable$: Observable<IAPIResponse<IDataStreamMap>>;
    if (shareToken) {
      reqObservable$ = this.ws.unAuthenticatedAsyncRequest$(
        'GET_DATA_STREAMS',
        { widgetIds },
        shareToken,
      );
    } else {
      reqObservable$ = this.ws.asyncRequest$('GET_DATA_STREAMS', { widgetIds });
    }
    return reqObservable$.pipe(
      map((response: IAPIResponse<IDataStreamMap>) => {
        if (response.success) {
          return ctx.dispatch(
            new App.FetchDataStreamsSuccess(
              fetchColumnInfo,
              response.res,
              refreshDataStreamState,
              loadPossibleValues,
              shareToken,
            ),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new App.FetchDataStreamsFail(e));
      }),
    );
  }

  @Action(App.FetchDataStreamsSuccess) fetchDataStreamsSuccess(
    ctx: StateContext<IAppStateModel>,
    action: App.FetchDataStreamsSuccess,
  ) {
    const state = ctx.getState();
    const existingDataStreams = state.appState.dataStreamsState.dataStreams;
    const newDataStreams = action.dataStreams;
    const fetchColumnInfo = action.fetchColumnInfo;
    const refreshDataStreamState = action.refreshAllDataStreams;
    const shareToken = action.shareToken;
    const loadPossibleValues = action.loadPossibleValues;

    // With the data streams, we need to update the dataStreamIds array
    // in the current dashboard
    const currentDashboard = state.appState.currentDashboard;

    if (!!currentDashboard && !!currentDashboard?.widgetIds) {
      // We need to get a new array of dataStreamIds that includes the new data streams.
      // To do this, we need all of the calculation data streams
      const dashboardWidgets = currentDashboard.widgetIds.map((widgetId) => {
        return state.appState.widgets[widgetId];
      });
      const dashboardDataStreamIds = [
        ...new Set(
          dashboardWidgets.flatMap((widget) => {
            return Object.values(widget.calculations).map((calculation) => {
              return calculation.dataStreamId;
            });
          }),
        ),
      ];
      ctx.setState(
        patch<IAppStateModel>({
          appState: patch<IAppState>({
            currentDashboard: patch<IDashV2State>({
              dataStreamIds: dashboardDataStreamIds,
            }),
          }),
        }),
      );
    }

    if (refreshDataStreamState) {
      // If we are refreshing the data stream state, then we need to
      // overwrite the existing data streams
      ctx.setState(
        patch<IAppStateModel>({
          appState: patch<IAppState>({
            dataStreamsState: patch<IDataStreamState>({
              dataStreams: newDataStreams,
              isLoading: false,
            }),
          }),
        }),
      );
    } else {
      // Update each data stream in the dataStreams state, but don't overwrite
      // the column info if it already exists
      Object.keys(newDataStreams).forEach((dataStreamId) => {
        existingDataStreams[dataStreamId] = {
          ...existingDataStreams[dataStreamId],
          ...newDataStreams[dataStreamId],
        };
      });

      ctx.setState(
        patch<IAppStateModel>({
          appState: patch<IAppState>({
            dataStreamsState: patch<IDataStreamState>({
              dataStreams: patch<IDataStreamMap>({
                ...existingDataStreams,
              }),
              isLoading: false,
            }),
          }),
        }),
      );
    }
    if (fetchColumnInfo) {
      const dataStreamIds = Object.keys(newDataStreams);
      ctx.dispatch(
        new App.FetchDataStreamColumns(
          dataStreamIds,
          loadPossibleValues,
          shareToken,
        ),
      );
    }
  }

  @Action(App.FetchDataStreamsFail) fetchDataStreamsFail(
    ctx: StateContext<IAppStateModel>,
    action: App.FetchDataStreamsFail,
  ) {
    // Set the dataStreams state to not loading
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          dataStreamsState: patch<IDataStreamState>({
            isLoading: false,
          }),
        }),
      }),
    );

    console.error(action.error);
  }
  @Action(App.GetFullModalData) async GetFullModalData(
    ctx: StateContext<IAppStateModel>,
    action: App.GetFullModalData,
  ) {
    const { calcId, label } = action;
    const shareToken = this.store.selectSnapshot(
      (state) =>
        (state.app as IAppStateModel).appState.currentDashboard.shareToken,
    );
    let res: IAPIResponse<string>;
    if (shareToken) {
      res = await this.ws.unAuthenticatedAsyncRequest(
        'GET_FULL_MODAL_DATA',
        calcId,
        shareToken,
      );
    } else {
      res = await this.ws.asyncRequest('GET_FULL_MODAL_DATA', calcId);
    }

    if (!res.success || !res.res) {
      return ctx.dispatch(
        new App.GetFullModalDataFail(Error("Couldn't get full modal data")),
      );
    }
    const url = `${environment.backendUrl}/modal-data/${res.res}/yuhboi`;
    const start = performance.now();

    const data: any[] = [];
    this.papa.parse(url, {
      download: true,
      delimiter: ',',
      dynamicTyping: true,
      header: true,
      step(results, parser) {
        data.push(results.data);
      },
      complete(results) {
        // Remove the last row if it only has one value and it's null
        if (
          Object.keys(data[data.length - 1]).length === 1 &&
          data[data.length - 1][Object.keys(data[data.length - 1])[0]] === null
        ) {
          data.pop();
        }

        ctx.dispatch(new App.GetFullModalDataSuccess(calcId, data));
      },
    });
  }

  @Action(App.GetFullModalDataSuccess) GetFullModalDataSuccess(
    ctx: StateContext<IAppStateModel>,
    action: App.GetFullModalDataSuccess,
  ) {
    let { calcId, data } = action;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          fullModalData: patch<Record<string, any[]>>({
            [calcId]: data,
          }),
        }),
      }),
    );
  }

  @Action(App.GetFullModalDataFail) GetFullModalDataFail(
    ctx: StateContext<IAppStateModel>,
    action: App.GetFullModalDataFail,
  ) {
    console.error(action.error);
  }

  @Action(App.FetchDataStreamColumns) fetchDataStreamColumns(
    ctx: StateContext<IAppStateModel>,
    action: App.FetchDataStreamColumns,
  ) {
    const { dataStreamIds, loadPossibleValues, shareToken } = action;

    if (dataStreamIds.length === 0) {
      ctx.dispatch(
        new App.FetchDataStreamColumnsSuccess({}, loadPossibleValues),
      );
      return;
    }

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          dataStreamsState: patch<IDataStreamState>({
            isLoading: true,
          }),
        }),
      }),
    );
    let reqObservable$: Observable<IAPIResponse<IDataStreamColumnMap>>;
    if (shareToken) {
      reqObservable$ = this.ws.unAuthenticatedAsyncRequest$(
        'GET_DATA_STREAM_COLUMNS',
        { dataStreams: dataStreamIds, loadPossibleValues: loadPossibleValues },
        shareToken,
      );
    } else {
      reqObservable$ = this.ws.asyncRequest$('GET_DATA_STREAM_COLUMNS', {
        dataStreams: dataStreamIds,
        loadPossibleValues: loadPossibleValues,
      });
    }
    return reqObservable$.pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(
            new App.FetchDataStreamColumnsSuccess(
              response.res,
              loadPossibleValues,
            ),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new App.FetchDataStreamColumnsFail(e));
      }),
    );
  }

  @Action(App.FetchDataStreamColumnsSuccess) fetchDataStreamColumnsSuccess(
    ctx: StateContext<IAppStateModel>,
    action: App.FetchDataStreamColumnsSuccess,
  ) {
    const state = ctx.getState();
    const dataStreams = state.appState.dataStreamsState.dataStreams;
    const dataStreamColumns = action.response;
    for (const dataStreamId in dataStreamColumns) {
      const dataStream = dataStreams[dataStreamId];
      const columnDetails = dataStreamColumns[dataStreamId];
      const columnNames = columnDetails.map((column) => column.name);
      const columnValues: IColumnValues = {};
      const columnDataTypes = {};
      columnDetails.forEach((column) => {
        if (!column.possibleValues) {
          column.possibleValues = dataStream?.columnValues?.[
            column.name
          ] as string[];
        } else {
          columnValues[column.name] = column.possibleValues;
        }
        columnDataTypes[column.name] = column.dataType;
      });
      dataStreams[dataStreamId].columnDetails = columnDetails;
      dataStreams[dataStreamId].columnNames = columnNames;
      dataStreams[dataStreamId].columnDataTypes = columnDataTypes;
      if (action.loadPossibleValues) {
        dataStreams[dataStreamId].columnValues = columnValues;
      }
    }

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          dataStreamsState: patch<IDataStreamState>({
            dataStreams: patch<IDataStreamMap>({
              ...dataStreams,
            }),
            isLoading: false,
          }),
        }),
      }),
    );
  }

  @Action(App.FetchDataStreamColumnsFail) fetchDataStreamColumnsFail(
    ctx: StateContext<IAppStateModel>,
    action: App.FetchDataStreamColumnsFail,
  ) {
    // Set the data stream state to not loading
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          dataStreamsState: patch<IDataStreamState>({
            isLoading: false,
          }),
        }),
      }),
    );

    console.error(action.error);
  }

  @Action(App.FetchDataSources) fetchDataSources(
    ctx: StateContext<IAppStateModel>,
    action: App.FetchDataSources,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          dataSourcesState: patch<IDataSourcesState>({
            isLoading: true,
          }),
        }),
      }),
    );

    return this.ws.asyncRequest$('LOAD_ALL_DATA_SOURCES').pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(new App.FetchDataSourcesSuccess(response.res));
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new App.FetchDataSourcesFail(e));
      }),
    );
  }

  @Action(App.FetchDataSourcesSuccess) fetchDataSourcesSuccess(
    ctx: StateContext<IAppStateModel>,
    action: App.FetchDataSourcesSuccess,
  ) {
    const { dataSources } = action;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          dataSourcesState: patch<IDataSourcesState>({
            dataSources,
            isLoading: false,
          }),
        }),
      }),
    );
  }

  @Action(App.FetchDataSourcesFail) fetchDataSourcesFail(
    ctx: StateContext<IAppStateModel>,
    action: App.FetchDataSourcesFail,
  ) {
    // Set the data stream state to not loading
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          dataSourcesState: patch<IDataSourcesState>({
            isLoading: false,
          }),
        }),
      }),
    );

    console.error(action.error);
  }

  @Action(App.DeleteDataStream) deleteDataStream(
    ctx: StateContext<IAppStateModel>,
    action: App.DeleteDataStream,
  ) {
    const { dataStreamId, dataStreamType } = action;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          dataStreamsState: patch<IDataStreamState>({
            isLoading: true,
          }),
        }),
      }),
    );

    return this.ws
      .asyncRequest$('DELETE_DATA_STREAM', { id: dataStreamId, dataStreamType })
      .pipe(
        map((response) => {
          if (response.success) {
            return ctx.dispatch(
              new App.DeleteDataStreamSuccess(response.res.id),
            );
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((e) => {
          return ctx.dispatch(new App.DeleteDataStreamFail(e));
        }),
      );
  }

  @Action(App.DeleteDataStreamSuccess) deleteDataStreamSuccess(
    ctx: StateContext<IAppStateModel>,
    action: App.DeleteDataStreamSuccess,
  ) {
    const { dataStreamId } = action;
    const state = ctx.getState();
    const dataStreams = state.appState.dataStreamsState.dataStreams;
    delete dataStreams[dataStreamId];

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          dataStreamsState: patch<IDataStreamState>({
            dataStreams: patch<IDataStreamMap>({
              ...dataStreams,
            }),
            isLoading: false,
          }),
        }),
      }),
    );
  }

  @Action(App.DeleteDataStreamFail) deleteDataStreamFail(
    ctx: StateContext<IAppStateModel>,
    action: App.DeleteDataStreamFail,
  ) {
    // Set the data stream state to not loading
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          dataStreamsState: patch<IDataStreamState>({
            isLoading: false,
          }),
        }),
      }),
    );

    console.error(action.error);
  }

  @Action(App.CopyDataStream) copyDataStream(
    ctx: StateContext<IAppStateModel>,
    action: App.CopyDataStream,
  ) {
    const { dataStreamId, dataStreamType } = action;
    return this.ws.asyncRequest$('COPY_DATA_STREAM', action).pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(
            new App.CopyDataStreamSuccess(
              response.res.dataStreamId,
              response.res.dataStreamType,
            ),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new App.CopyDataStreamFail(e));
      }),
    );
  }
  @Action(App.CopyDataStreamSuccess) copyDataStreamSuccess(
    ctx: StateContext<IAppStateModel>,
    action: App.CopyDataStreamSuccess,
  ) {
    ctx.dispatch(new App.FetchDataStreams(false));
  }
  @Action(App.CopyDataStreamFail) copyDataStreamFail(
    ctx: StateContext<IAppStateModel>,
    action: App.CopyDataStreamFail,
  ) {
    console.error(action.error);
  }

  @Action(App.ResyncDataStream) resyncDataStream(
    ctx: StateContext<IAppStateModel>,
    action: App.ResyncDataStream,
  ) {
    const { dataStreamId } = action;

    return this.ws.asyncRequest$('RESYNC_TABLE', { id: dataStreamId }).pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(new App.ResyncDataStreamSuccess(response.res.id));
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new App.ResyncDataStreamFail(e));
      }),
    );
  }

  @Action(App.ResyncDataStreamSuccess) resyncDataStreamSuccess(
    ctx: StateContext<IAppStateModel>,
    action: App.ResyncDataStreamSuccess,
  ) {
    const { dataStreamId } = action;
  }

  @Action(App.ResyncDataStreamFail) resyncDataStreamFail(
    ctx: StateContext<IAppStateModel>,
    action: App.ResyncDataStreamFail,
  ) {
    console.error(action.error);
  }

  @Action(App.FetchListOfItemsForDataStreamDeletion)
  fetchListOfItemsForDataStreamDeletion(
    ctx: StateContext<IAppStateModel>,
    action: App.FetchListOfItemsForDataStreamDeletion,
  ) {
    const { dataStreamId, dataStreamType } = action;

    return this.ws
      .asyncRequest$('LIST_OF_ITEMS_FOR_DATA_STREAM_DELETION', {
        id: dataStreamId,
        dataStreamType,
      })
      .pipe(
        map((response) => {
          if (response.success) {
            return ctx.dispatch(
              new App.FetchListOfItemsForDataStreamDeletionSuccess(
                dataStreamId,
                dataStreamType,
                response.res,
              ),
            );
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((e) => {
          return ctx.dispatch(
            new App.FetchListOfItemsForDataStreamDeletionFail(e),
          );
        }),
      );
  }

  @Action(App.FetchListOfItemsForDataStreamDeletionSuccess)
  fetchListOfItemsForDataStreamDeletionSuccess(
    ctx: StateContext<IAppStateModel>,
    action: App.FetchListOfItemsForDataStreamDeletionSuccess,
  ) {
    // Nothing to do here for now
  }

  @Action(App.FetchListOfItemsForDataStreamDeletionFail)
  fetchListOfItemsForDataStreamDeletionFail(
    ctx: StateContext<IAppStateModel>,
    action: App.FetchListOfItemsForDataStreamDeletionFail,
  ) {
    console.error(action.error);
  }

  @Action(App.GetImpersonationOptions) getImpersionationOptions(
    ctx: StateContext<IAppStateModel>,
    action: App.GetImpersonationOptions,
  ) {
    return this.ws.asyncRequest$('GET_IMPERSONATION_OPTIONS').pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(
            new App.GetImpersonationOptionsSuccess(response.res),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new App.GetImpersonationOptionsFail(e));
      }),
    );
  }
  @Action(App.SetImpersonationCustomerId) setImpersonationCustomerId(
    ctx: StateContext<IAppStateModel>,
    action: App.SetImpersonationCustomerId,
  ) {
    const { id, refresh } = action;
    const state = ctx.getState();
    if (id === state.appState.impersonatingCustomerId) {
      return;
    }
    localStorage.setItem('impersonatingCustomerId', id);
    if (refresh) {
      if (this.router.url.startsWith('/dash-v2/')) {
        this.router.navigate(['/dashboards']).then(() => {
          window.location.reload();
        });
      } else {
        window.location.reload();
      }
    }

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          impersonatingCustomerId: id,
        }),
      }),
    );
  }
  @Action(App.GetImpersonationOptionsSuccess) getImpersionationOptionsSuccess(
    ctx: StateContext<IAppStateModel>,
    action: App.GetImpersonationOptionsSuccess,
  ) {
    const { options } = action;
    const state = ctx.getState();

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          impersonationOptions: options,
        }),
      }),
    );
  }

  @Action(App.GetImpersonationOptionsFail) getImpersionationOptionsFail(
    ctx: StateContext<IAppStateModel>,
    action: App.GetImpersonationOptionsFail,
  ) {
    // Set the data stream state to not loading

    console.error(action.error);
  }

  @Action(App.SaveRecentDashboard) saveRecentDashboard(
    ctx: StateContext<IAppStateModel>,
    action: App.SaveRecentDashboard,
  ) {
    const { dashId } = action;
    return this.ws
      .asyncRequest$('SAVE_RECENT_DASHBOARD', { dashboardId: dashId })
      .pipe(
        map((response) => {
          if (response.success) {
            return ctx.dispatch(
              new App.SaveRecentDashboardSuccess(
                response.res.recent_dashboards,
                response.res.dashboard_uuid,
              ),
            );
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((e) => {
          return ctx.dispatch(new App.SaveRecentDashboardFail(e));
        }),
      );
  }

  @Action(App.SaveRecentDashboardSuccess) saveRecentDashboardSuccess(
    ctx: StateContext<IAppStateModel>,
    action: App.SaveRecentDashboardSuccess,
  ) {
    const { recentDashboards, addedDashId } = action;
    this.legacyGlobalState.setRecentDashboards(recentDashboards, addedDashId);
  }

  @Action(App.SaveRecentDashboardFail) saveRecentDashboardFail(
    ctx: StateContext<IAppStateModel>,
    action: App.SaveRecentDashboardFail,
  ) {
    console.error(action.error);
  }

  @Action(App.RefreshUserPreferences) refreshUserPreferences(
    ctx: StateContext<IAppStateModel>,
  ) {
    this.legacyGlobalState.loadUserPreferences();
  }

  @Action(App.ResetState) resetState(ctx: StateContext<IAppStateModel>) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>(initialAppState()),
      }),
    );
  }

  @Action(App.GetTemplates) getTemplates(
    ctx: StateContext<IAppStateModel>,
    action: App.GetTemplates,
  ) {
    return this.ws.asyncRequest$('GET_TEMPLATES').pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(
            new App.GetTemplatesSuccess(response.res.templates),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new App.GetTemplatesFail(e));
      }),
    );
  }

  @Action(App.GetTemplatesSuccess) getTemplatesSuccess(
    ctx: StateContext<IAppStateModel>,
    action: App.GetTemplatesSuccess,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          templates: action.templates,
        }),
      }),
    );
  }

  @Action(App.GetTemplatesFail) getTemplatesFail(
    ctx: StateContext<IAppStateModel>,
    action: App.GetTemplatesFail,
  ) {
    console.error(action.error);
  }

  @Action(App.UseTemplate) useTemplates(
    ctx: StateContext<IAppStateModel>,
    action: App.UseTemplate,
  ) {
    return this.ws.asyncRequest$('USE_TEMPLATE', action.request).pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(new App.UseTemplateSuccess(action.request.id));
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new App.UseTemplateFail(e));
      }),
    );
  }

  @Action(App.UseTemplateSuccess) useTemplatesSuccess(
    ctx: StateContext<IAppStateModel>,
    action: App.UseTemplateSuccess,
  ) {
    let temps = ctx.getState().appState.templates;
    let i = temps.findIndex((template) => template.id === action.id);
    if (i === -1) return;
    this.eventQueue.dispatch('SHOW_TOAST', {
      status: 'success',
      message: 'Template applied successfully.',
      title: 'Nice!',
    });
    temps[i].alreadyExists = true;
    this.store.dispatch(new Dash.FetchDashboards());

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          templates: temps,
        }),
      }),
    );
  }

  @Action(App.UseTemplateFail) useTemplatesFail(
    ctx: StateContext<IAppStateModel>,
    action: App.UseTemplateFail,
  ) {
    console.error(action.error);
  }

  @Action(App.GetTagOptions) getTagOptions(
    ctx: StateContext<IAppStateModel>,
    action: App.GetTagOptions,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          tagOptions: patch<ITagOptionsState>({
            isLoading: true,
          }),
        }),
      }),
    );
    return this.ws.asyncRequest$('GET_TAG_OPTIONS').pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(new App.GetTagOptionsSuccess(response.res));
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new App.GetTagOptionsFail(e));
      }),
    );
  }

  @Action(App.GetTagOptionsSuccess) getTagOptionsSuccess(
    ctx: StateContext<IAppStateModel>,
    action: App.GetTagOptionsSuccess,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          tagOptions: {
            tags: action.tags,
            isLoading: false,
          },
        }),
      }),
    );
  }

  @Action(App.GetTagOptionsFail) getTagOptionsFail(
    ctx: StateContext<IAppStateModel>,
    action: App.GetTagOptionsFail,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          tagOptions: patch<ITagOptionsState>({
            isLoading: false,
          }),
        }),
      }),
    );
    console.error(action.error);
  }

  @Action(App.DeleteTag) deleteTag(
    ctx: StateContext<IAppStateModel>,
    action: App.DeleteTag,
  ) {
    return this.ws.asyncRequest$('DELETE_TAG', { id: action.id }).pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(new App.DeleteTagSuccess(action.id));
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new App.DeleteTagFail(e));
      }),
    );
  }

  @Action(App.DeleteTagSuccess) deleteTagSuccess(
    ctx: StateContext<IAppStateModel>,
    action: App.DeleteTagSuccess,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          tagOptions: patch<ITagOptionsState>({
            tags: ctx
              .getState()
              .appState.tagOptions.tags.filter((tag) => tag.id !== action.id),
          }),
        }),
      }),
    );
  }

  @Action(App.DeleteTagFail) deleteTagFail(
    ctx: StateContext<IAppStateModel>,
    action: App.DeleteTagFail,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          tagOptions: patch<ITagOptionsState>({
            isLoading: false,
          }),
        }),
      }),
    );
    console.error(action.error);
  }

  @Action(App.GetSoundboard) getSoundboard(
    ctx: StateContext<IAppStateModel>,
    action: App.GetSoundboard,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          soundboard: patch<ISoundboardState>({
            isLoading: true,
          }),
        }),
      }),
    );
    return this.ws.asyncRequest$('GET_SOUNDBOARD').pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(new App.GetSoundboardSuccess(response.res));
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new App.GetSoundboardFail(e));
      }),
    );
  }

  @Action(App.GetSoundboardSuccess) getSoundboardSuccess(
    ctx: StateContext<IAppStateModel>,
    action: App.GetSoundboardSuccess,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          soundboard: {
            sounds: action.sounds,
            isLoading: false,
          },
        }),
      }),
    );
  }

  @Action(App.GetSoundboardFail) getSoundboardFail(
    ctx: StateContext<IAppStateModel>,
    action: App.GetSoundboardFail,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          soundboard: patch<ISoundboardState>({
            isLoading: false,
          }),
        }),
      }),
    );
    console.error(action.error);
  }

  @Action(App.DeleteSound) deleteSound(
    ctx: StateContext<IAppStateModel>,
    action: App.DeleteSound,
  ) {
    return this.ws.asyncRequest$('DELETE_SOUND', { id: action.id }).pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(new App.DeleteSoundSuccess(action.id));
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new App.DeleteSoundFail(e));
      }),
    );
  }

  @Action(App.DeleteSoundSuccess) deleteSoundSuccess(
    ctx: StateContext<IAppStateModel>,
    action: App.DeleteSoundSuccess,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          soundboard: patch<ISoundboardState>({
            sounds: ctx
              .getState()
              .appState.soundboard.sounds.filter(
                (sound) => sound.id !== action.id,
              ),
          }),
        }),
      }),
    );
  }

  @Action(App.DeleteSoundFail) deleteSoundFail(
    ctx: StateContext<IAppStateModel>,
    action: App.DeleteSoundFail,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          soundboard: patch<ISoundboardState>({
            isLoading: false,
          }),
        }),
      }),
    );
    console.error(action.error);
  }

  @Action(App.EnableFullScreen) enableFullScreen(
    ctx: StateContext<IAppStateModel>,
    action: App.EnableFullScreen,
  ) {
    const sidebarTags = ['app-sidebar', 'utility-sidebar'];
    sidebarTags.forEach((tag) => {
      this.sidebarService.collapse(tag);
    });
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          frontEndState: patch<IFrontEndState>({
            fullScreen: true,
          }),
        }),
      }),
    );
  }

  @Action(App.DisableFullScreen) disableFullScreen(
    ctx: StateContext<IAppStateModel>,
    action: App.DisableFullScreen,
  ) {
    this.sidebarService.expand('app-sidebar');
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          frontEndState: patch<IFrontEndState>({
            fullScreen: false,
          }),
        }),
      }),
    );
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////                Dash                 /////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Action(Dash.FetchDash) fetchDash(
    ctx: StateContext<IAppStateModel>,
    action: Dash.FetchDash,
  ) {
    const { id } = action;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          currentDashboard: patch<IDashV2State>({
            isLoading: true,
          }),
        }),
      }),
    );
    return this.ws.asyncRequest$('GET_DASHBOARD_V2', { id }).pipe(
      map((response: IAPIResponse<IGetDashboardV2Response>) => {
        // If no dash found send the user to 404 page
        if (!response.res) {
          // Replace url so the user is not annoyed by the back button redirecting them in a loop
          this.router.navigate(['/dash-not-found'], { replaceUrl: true });
          return;
        }
        if (response.success) {
          return ctx.dispatch(new Dash.FetchDashSuccess(response.res));
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((err) => {
        return ctx.dispatch(new Dash.FetchDashFail(err));
      }),
    );
  }

  @Action(Dash.FetchDashSuccess) fetchDashSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Dash.FetchDashSuccess,
  ) {
    const { dashboard, filterVariables } = action.res;
    const state = ctx.getState();

    // Update the filter view to include isLoading and isEditing
    const filterViews: IFilterViews = {};
    Object.values(dashboard.filterViews).forEach((filterView) => {
      filterViews[filterView.id] = {
        ...filterView,
        isLoading: false,
        isEditing: false,
        activeFilterViewItem: null,
      };
    });

    // Since the widgets rely on the current dashboard state, we need to update the widgets after the current dashboard state
    // has been updated.
    const widgetStorables: IStorableWidgetV2[] = [];
    const dataStreamIds: string[] = [];
    dashboard.widgets.forEach((widget) => {
      widgetStorables.push(widget);
      const widgetCalculations = widget.calculations;
      if (widgetCalculations) {
        dataStreamIds.push(
          ...Object.values(widgetCalculations).map((calc) => calc.dataStreamId),
        );
      }
    });
    const widgetIds = dashboard.widgets.map((widget) => widget.id);

    const currentUserId =
      this.legacyGlobalState.sessionVariables.user.user_uuid;
    const activeFilterViewId =
      dashboard.userFilterViewState?.[currentUserId] ||
      dashboard.activeFilterViewId;

    // First, update the current dashboard state
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          currentDashboard: patch<IDashV2State>({
            ...dashboard,
            activeFilterViewId,
            isLoading: false,
            savingTitle: false,
            widgetIds,
            displayState: 'dashboard',
            gridsterConfig: state.appState.currentDashboard?.gridsterConfig,
            selectingFilterViewItemWidgets: false,
            dataStreamIds: [...new Set(dataStreamIds)], // Remove duplicates
            isShared: false,
            showModalData: true,
            // Explicitly set to undefined since it's a prop of dash in response but
            // in state widgets are stored separately
            widgets: undefined,
          }),
          filterViews,
        }),
      }),
    );

    const widgets: IWidgetStateMap = {};
    widgetStorables.forEach((widget) => {
      widgets[widget.id] = new WidgetStateObject(widget, this.store);
    });
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets,
        }),
      }),
    );
    ctx.dispatch(
      new FilterVariable.GetFilterVariablesStateSuccess(filterVariables),
    );
    ctx.dispatch(new Dash.InitializeDashboardWidgetData());
    ctx.dispatch(new App.SaveRecentDashboard(dashboard.id));
  }

  @Action(Dash.FetchDashFail) fetchDashFail(
    ctx: StateContext<IAppStateModel>,
    action: Dash.FetchDashFail,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          currentDashboard: patch<IDashV2State>({
            isLoading: false,
          }),
        }),
      }),
    );
    console.error(action.error);
  }

  @Action(Dash.FetchSharedDash) fetchSharedDash(
    ctx: StateContext<IAppStateModel>,
    action: Dash.FetchSharedDash,
  ) {
    const token = action.token;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          currentDashboard: patch<IDashV2State>({
            isLoading: true,
          }),
        }),
      }),
    );
    //sadfs
    return this.ws
      .unAuthenticatedAsyncRequest$(
        'GET_SHARED_DASHBOARD_V2',
        { auth: action.password },
        token,
      )
      .pipe(
        map((response: IAPIResponse<IGetSharedDashboardV2Response>) => {
          if (response.success) {
            return ctx.dispatch(
              new Dash.FetchSharedDashSuccess(response.res, token),
            );
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((err) => {
          return ctx.dispatch(new Dash.FetchSharedDashFail(err));
        }),
      );
  }

  @Action(Dash.FetchSharedDashSuccess) fetchSharedDashSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Dash.FetchSharedDashSuccess,
  ) {
    const { dashboard, theme, showModalData, filterVariables } = action.res;
    const token = action.token;
    const state = ctx.getState();

    // Update the filter view to include isLoading and isEditing
    const filterViews: IFilterViews = {};
    Object.values(dashboard.filterViews).forEach((filterView) => {
      filterViews[filterView.id] = {
        ...filterView,
        isLoading: false,
        isEditing: false,
        activeFilterViewItem: null,
      };
    });

    // Since the widgets rely on the current dashboard state, we need to update the widgets after the current dashboard state
    // has been updated.
    const widgetStorables: IStorableWidgetV2[] = [];
    dashboard.widgets.forEach((widget) => {
      widgetStorables.push(widget);
    });
    const widgetIds = dashboard.widgets.map((widget) => widget.id);

    // First, update the current dashboard state
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          currentDashboard: patch<IDashV2State>({
            ...dashboard,
            isLoading: false,
            savingTitle: false,
            widgetIds,
            displayState: 'dashboard',
            gridsterConfig: state.appState.currentDashboard?.gridsterConfig,
            selectingFilterViewItemWidgets: false,
            dataStreamIds: [],
            isShared: true,
            shareToken: token,
            theme,
            showModalData,
            // Explicitly set to undefined since it's a prop of dash in response but
            // in state widgets are stored separately
            widgets: undefined,
          }),
          filterViews: patch<IFilterViews>({
            ...filterViews,
          }),
          frontEndState: patch<IFrontEndState>({
            shareToken: token,
          }),
        }),
      }),
    );
    const widgets: IWidgetStateMap = {};
    widgetStorables.forEach((widget) => {
      widgets[widget.id] = new WidgetStateObject(widget, this.store);
    });
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets,
        }),
      }),
    );
    ctx.dispatch(
      new FilterVariable.GetFilterVariablesStateSuccess(filterVariables),
    );
    ctx.dispatch(new Dash.InitializeDashboardWidgetData());
  }

  @Action(Dash.FetchSharedDashFail) fetchSharedDashFail(
    ctx: StateContext<IAppStateModel>,
    action: Dash.FetchSharedDashFail,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          currentDashboard: patch<IDashV2State>({
            isLoading: false,
          }),
        }),
      }),
    );
    console.error(action.error);
  }

  @Action(Dash.InitializeDashboardWidgetData) initializeDashboardWidgetData(
    ctx: StateContext<IAppStateModel>,
  ) {
    const state = ctx.getState();
    const dashboardShareToken = state.appState.currentDashboard.shareToken;
    const widgetIds = state.appState.currentDashboard.widgetIds;
    const dashboardWidgets = Object.values(state.appState.widgets).filter(
      (widget) => widgetIds.includes(widget.id),
    );
    const calculationIdsThatNeedData: string[] = [];
    dashboardWidgets.forEach((widget) => {
      const activeCalcIds = widget.calcArray
        .map((calc) => calc?.activeCalcDataId)
        .filter((id) => id);
      calculationIdsThatNeedData.push(...activeCalcIds);
    });
    ctx.dispatch(
      new Widget.GetCalculationData(
        calculationIdsThatNeedData,
        widgetIds,
        false,
        false,
        dashboardShareToken,
      ),
    );
    ctx.dispatch(
      new App.FetchDataStreams(
        true,
        widgetIds,
        false,
        false,
        dashboardShareToken,
      ),
    );
  }

  @Action(Dash.ReinitializeDashboardWidgets) reinitializeDashboardWidgets(
    ctx: StateContext<IAppStateModel>,
  ) {
    const state = ctx.getState();
    const currentDashboard = state.appState.currentDashboard;
    const dashboardWidgetIds = currentDashboard.widgetIds;
    const widgets = state.appState.widgets;
    for (const widgetId of dashboardWidgetIds) {
      let widget = widgets[widgetId];
      widget = widget.reInitialize();
      widget.isLoading = false;
      widgets[widgetId] = widget;
    }
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets,
        }),
      }),
    );
  }

  @Action(Dash.FetchDashboards) fetchDashboards(
    ctx: StateContext<IAppStateModel>,
    action: Dash.FetchDashboards,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          allDashboards: patch<IAllDashboardsState>({
            isLoading: true,
          }),
        }),
      }),
    );

    // This is handled somewhere else in legacy code
    const req = this.ws.asyncRequest$('LOAD_DASHBOARD_INFO');
    req.pipe(first()).subscribe((response) => {
      if (response.success) {
        ctx.setState(
          patch<IAppStateModel>({
            appState: patch<IAppState>({
              allDashboards: patch<IAllDashboardsState>({
                dashboards: response.res,
                isLoading: false,
              }),
            }),
          }),
        );
      }
    });
    return req;
  }

  @Action(Dash.CreateNewDash) createNewDash(
    ctx: StateContext<IAppStateModel>,
    action: Dash.CreateNewDash,
  ) {
    const { title, description } = action;
    return this.ws
      .asyncRequest$('CREATE_DASHBOARD_V2', { title, description })
      .pipe(
        map((response: IAPIResponse<ICreateDashboardV2Response>) => {
          if (response.success) {
            return ctx.dispatch(new Dash.CreateNewDashSuccess(response.res.id));
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((err) => {
          return ctx.dispatch(new Dash.CreateNewDashFail(err));
        }),
      );
  }

  @Action(Dash.CreateNewDashSuccess) createNewDashSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Dash.CreateNewDashSuccess,
  ) {
    ctx.dispatch(new Dash.FetchDashboards());
  }

  @Action(Dash.CreateNewDashFail) createNewDashFail(
    ctx: StateContext<IAppStateModel>,
    action: Dash.CreateNewDashFail,
  ) {
    console.error(action.error);
  }

  @Action(Dash.SavePositions) savePositions(ctx: StateContext<IAppStateModel>) {
    const state = ctx.getState();
    const dashboardWidgetIds = state.appState.currentDashboard.widgetIds;
    const widgets = state.appState.widgets;
    const payload: IUpdateWidgetsPositionsRequestPayload = { items: {} };
    dashboardWidgetIds.forEach((widgetId) => {
      const widget = widgets[widgetId].reInitialize();
      payload.items[widgetId] = widget.gridsterItem;
      widgets[widgetId] = widget;
    });
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets,
        }),
      }),
    );
    return this.ws.asyncRequest$('UPDATE_WIDGETS_POSITIONS', payload).pipe(
      map((response: IAPIResponse<null>) => {
        if (response.success) {
          return ctx.dispatch(new Dash.SavePositionsSuccess());
        }
        throw new Error(response.error);
      }),
      catchError((err) => {
        return ctx.dispatch(new Dash.SavePositionsFail(err));
      }),
    );
  }

  @Action(Dash.SavePositionsSuccess) savePositionsSuccess(
    ctx: StateContext<IAppStateModel>,
  ) {
    const state = ctx.getState();
    const dashboardWidgetIds = state.appState.currentDashboard.widgetIds;
    const widgets = state.appState.widgets;
    dashboardWidgetIds.forEach((widgetId) => {
      const widget = widgets[widgetId].reInitialize();
      widget.isLoading = false;
      ctx.setState(
        patch<IAppStateModel>({
          appState: patch<IAppState>({
            widgets: patch<IWidgetStateMap>({
              [widgetId]: widget,
            }),
          }),
        }),
      );
    });
  }

  @Action(Dash.SavePositionsFail) savePositionsFail(
    ctx: StateContext<IAppStateModel>,
    action: Dash.SavePositionsFail,
  ) {
    const state = ctx.getState();
    const dashboardWidgetIds = state.appState.currentDashboard.widgetIds;
    const widgets = state.appState.widgets;
    dashboardWidgetIds.forEach((widgetId) => {
      const widget = widgets[widgetId].reInitialize();
      widget.isLoading = false;
      widgets[widgetId] = widget;
    });
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets,
        }),
      }),
    );
    console.error(action.error);
  }

  @Action(Dash.ReloadCurrentDash) reload(
    ctx: StateContext<IAppStateModel>,
    action: Dash.ReloadCurrentDash,
  ) {
    ctx.dispatch(
      new Dash.FetchDash(ctx.getState().appState.currentDashboard.id),
    );
  }

  @Action(Dash.ChangeDisplayState) changeDisplayState(
    ctx: StateContext<IAppStateModel>,
    action: Dash.ChangeDisplayState,
  ) {
    const { displayState } = action;

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          currentDashboard: patch<IDashV2State>({
            displayState,
          }),
        }),
      }),
    );
  }

  @Action(Dash.StoreGridsterConfig) storeGridsterConfig(
    ctx: StateContext<IAppStateModel>,
    action: Dash.StoreGridsterConfig,
  ) {
    const state = ctx.getState();
    ctx.setState({
      appState: {
        ...state.appState,
        currentDashboard: {
          ...state.appState.currentDashboard,
          gridsterConfig: action.config,
        },
      },
    });
  }

  @Action(Dash.DeleteDash) deleteDash(
    ctx: StateContext<IAppStateModel>,
    action: Dash.DeleteDash,
  ) {
    const { id } = action;
    return this.ws.asyncRequest$('DELETE_DASHBOARD_V2', { id }).pipe(
      map((response: IAPIResponse<IDeleteDashboardV2Response>) => {
        if (response.success) {
          return ctx.dispatch(new Dash.DeleteDashSuccess(response.res.id));
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((err) => {
        return ctx.dispatch(new Dash.DeleteDashFail(err));
      }),
    );
  }

  @Action(Dash.DeleteDashSuccess) deleteDashSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Dash.DeleteDashSuccess,
  ) {
    const state = ctx.getState();
    const { dashId } = action;
    const dashboards = [...state.appState.allDashboards.dashboards];
    let dashIndex = dashboards.findIndex((dash) => dash.id === dashId);
    if (dashIndex !== -1) dashboards.splice(dashIndex, 1);
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          allDashboards: {
            dashboards,
            isLoading: false,
          },
        }),
      }),
    );
    ctx.dispatch(new Dash.RequestCompanyDashRefresh());
    ctx.dispatch(new App.RefreshUserPreferences());
    ctx.dispatch(new Dash.FetchDashboards());
  }

  @Action(Dash.DeleteDashFail) deleteDashFail(
    ctx: StateContext<IAppStateModel>,
    action: Dash.DeleteDashFail,
  ) {
    console.error(action.error);
  }

  @Action(Dash.CopyDash) copyDash(
    ctx: StateContext<IAppStateModel>,
    action: Dash.CopyDash,
  ) {
    const { dashId } = action;
    return this.ws.asyncRequest$('COPY_DASHBOARD_V2', { id: dashId }).pipe(
      map((response: IAPIResponse<ICopyDashboardV2Response>) => {
        if (response.success) {
          return ctx.dispatch(new Dash.CopyDashSuccess(response.res.id));
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((err) => {
        return ctx.dispatch(new Dash.CopyDashFail(err));
      }),
    );
  }

  @Action(Dash.CopyDashSuccess) copyDashSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Dash.CopyDashSuccess,
  ) {
    const { dashId } = action;
  }

  @Action(Dash.CopyDashFail) copyDashFail(
    ctx: StateContext<IAppStateModel>,
    action: Dash.CopyDashFail,
  ) {
    console.error(action.error);
  }

  @Action(Dash.ClearCurrentDash) clearCurrentDash(
    ctx: StateContext<IAppStateModel>,
    action: Dash.ClearCurrentDash,
  ) {
    // First, unsubscribe from all calculation data
    const state = ctx.getState();
    const currentDashboardWidgetIds =
      state.appState.currentDashboard?.widgetIds;
    if (currentDashboardWidgetIds) {
      const widgetsToUnsubscribe = currentDashboardWidgetIds.map(
        (widgetId) => state.appState.widgets[widgetId],
      );
      let calculationsToUnsubscribe: string[] = [];
      for (const widget of widgetsToUnsubscribe) {
        const widgetCalcIds = widget.calcArray.map(
          (calc) => calc.activeCalcDataId,
        );
        calculationsToUnsubscribe =
          calculationsToUnsubscribe.concat(widgetCalcIds);
      }
      ctx.dispatch(
        new Widget.UnsubscribeFromCalculationData(calculationsToUnsubscribe),
      );
    }

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          currentDashboard: undefined,
        }),
      }),
    );
  }

  @Action(Dash.RequestCompanyDashRefresh) requestCompanyDashRefresh(
    ctx: StateContext<IAppStateModel>,
    action: Dash.RequestCompanyDashRefresh,
  ) {
    const state = ctx.getState();
    const id = state.appState.currentDashboard?.id;
    if (!id) return;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          currentDashboard: patch<IDashV2State>({
            requestedCompanyDashRefresh: true,
          }),
        }),
      }),
    );
    return this.ws.asyncRequest$('REFRESH_COMPANY_DASHBOARD', { id }).pipe(
      map((response: IAPIResponse<IRefreshCompanyDashboardResponse>) => {
        if (response.success) {
          return ctx.dispatch(
            new Dash.RequestCompanyDashRefreshSuccess(response.res.id),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((err) => {
        return ctx.dispatch(new Dash.RequestCompanyDashRefreshFail(err));
      }),
    );
  }

  @Action(Dash.RequestCompanyDashRefreshSuccess)
  requestCompanyDashRefreshSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Dash.RequestCompanyDashRefreshSuccess,
  ) {
    /**
     * This timeout is important. We have 2 listeners to the response for REFRESH_COMPANY_DASHBOARD.
     * One of them is a long-lived sub that all users have open, that one actually calls the dash refresh
     * logic. This is the second one. The first one has to run first and the flag is used to prevent the
     * user that requested the refresh from causing a refresh on their own dashboard, thus only other users
     * will get the refresh ping since they don't have the flag set.
     */
    setTimeout(() => {
      ctx.setState(
        patch<IAppStateModel>({
          appState: patch<IAppState>({
            currentDashboard: patch<IDashV2State>({
              requestedCompanyDashRefresh: false,
            }),
          }),
        }),
      );
    }, 100);
  }

  @Action(Dash.RequestCompanyDashRefreshFail) requestCompanyDashRefreshFail(
    ctx: StateContext<IAppStateModel>,
    action: Dash.RequestCompanyDashRefreshFail,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          currentDashboard: patch<IDashV2State>({
            requestedCompanyDashRefresh: false,
          }),
        }),
      }),
    );
  }

  @Action(Dash.SetDashSettingsState) setDashSettingsState(
    ctx: StateContext<IAppStateModel>,
    action: Dash.SetDashSettingsState,
  ) {
    const newSettings = action.dashSettingsState;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          currentDashboard: patch<IDashV2State>({
            ...newSettings,
          }),
        }),
      }),
    );
  }

  @Action(Dash.SettingsSave) settingsSave(
    ctx: StateContext<IAppStateModel>,
    action: Dash.SettingsSave,
  ) {
    const state = ctx.getState().appState;
    const currentDash = state.currentDashboard;
    return this.ws.asyncRequest$('UPDATE_DASHBOARD_V2', currentDash).pipe(
      map((response: IAPIResponse<null>) => {
        if (response.success) {
          return ctx.dispatch(new Dash.SettingsSaveSuccess());
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((err) => {
        return ctx.dispatch(new Dash.SettingsSaveFail(err));
      }),
    );
  }

  @Action(Dash.SettingsSaveSuccess) settingsSaveSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Dash.SettingsSaveSuccess,
  ) {
    ctx.dispatch(new Dash.SavePositions());
    ctx.dispatch(new Dash.RequestCompanyDashRefresh());
    ctx.dispatch(new Dash.FetchDashboards());
  }

  @Action(Dash.SettingsSaveFail) settingsSaveFail(
    ctx: StateContext<IAppStateModel>,
    action: Dash.SettingsSaveFail,
  ) {
    const { error } = action;
    console.error(error);
  }

  @Action(Dash.GetDashboardGroupOptions) getDashboardGroupOptions(
    ctx: StateContext<IAppStateModel>,
    action: Dash.GetDashboardGroupOptions,
  ) {
    return this.ws.asyncRequest$('GET_DASHBOARD_GROUP_OPTIONS').pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(
            new Dash.GetDashboardGroupOptionsSuccess(response.res),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((err) => {
        return ctx.dispatch(new Dash.GetDashboardGroupOptionsFail(err));
      }),
    );
  }

  @Action(Dash.GetDashboardGroupOptionsSuccess) getDashboardGroupOptionsSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Dash.GetDashboardGroupOptionsSuccess,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          dashboardGroups: action.dashboardGroups,
        }),
      }),
    );
  }

  @Action(Dash.GetDashboardGroupOptionsFail) getDashboardGroupOptionsFail(
    ctx: StateContext<IAppStateModel>,
    action: Dash.GetDashboardGroupOptionsFail,
  ) {
    const { error } = action;
    console.error(error);
  }

  @Action(Dash.CreateOrUpdateDashboardGroup) createOrUpdateDashboardGroup(
    ctx: StateContext<IAppStateModel>,
    action: Dash.CreateOrUpdateDashboardGroup,
  ) {
    return this.ws
      .asyncRequest$('CREATE_OR_UPDATE_DASHBOARD_GROUP', action.dashboard_group)
      .pipe(
        map((response) => {
          if (response.success) {
            return ctx.dispatch(new Dash.CreateOrUpdateDashboardGroupSuccess());
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((err) => {
          return ctx.dispatch(new Dash.CreateOrUpdateDashboardGroupFail(err));
        }),
      );
  }

  @Action(Dash.CreateOrUpdateDashboardGroupSuccess)
  createOrUpdateDashboardGroupSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Dash.CreateOrUpdateDashboardGroupSuccess,
  ) {
    ctx.dispatch(new Dash.GetDashboardGroupOptions());
  }

  @Action(Dash.CreateOrUpdateDashboardGroupFail)
  createOrUpdateDashboardGroupFail(
    ctx: StateContext<IAppStateModel>,
    action: Dash.CreateOrUpdateDashboardGroupFail,
  ) {
    const { error } = action;
    console.error(error);
  }

  @Action(Dash.DeleteDashboardGroup) deleteDashboardGroup(
    ctx: StateContext<IAppStateModel>,
    action: Dash.DeleteDashboardGroup,
  ) {
    return this.ws.asyncRequest$('DELETE_DASHBOARD_GROUP', action).pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(new Dash.DeleteDashboardGroupSuccess());
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((err) => {
        return ctx.dispatch(new Dash.DeleteDashboardGroupFail(err));
      }),
    );
  }

  @Action(Dash.DeleteDashboardGroupSuccess) deleteDashboardGroupSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Dash.DeleteDashboardGroupSuccess,
  ) {
    ctx.dispatch(new Dash.GetDashboardGroupOptions());
  }

  @Action(Dash.DeleteDashboardGroupFail) deleteDashboardGroupFail(
    ctx: StateContext<IAppStateModel>,
    action: Dash.DeleteDashboardGroupFail,
  ) {
    const { error } = action;
    console.error(error);
  }

  @Action(Dash.GetDashboardFolders) getDashboardFolders(
    ctx: StateContext<IAppStateModel>,
    action: Dash.GetDashboardFolders,
  ) {
    return this.ws.asyncRequest$('GET_DASHBOARD_FOLDERS').pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(
            new Dash.GetDashboardFoldersSuccess(response.res),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((err) => {
        return ctx.dispatch(new Dash.GetDashboardFoldersFail(err));
      }),
    );
  }

  @Action(Dash.GetDashboardFoldersSuccess) getDashboardFoldersSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Dash.GetDashboardFoldersSuccess,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          dashboardFolders: action.dashboardFolders,
        }),
      }),
    );
  }

  @Action(Dash.GetDashboardFoldersFail) getDashboardFoldersFail(
    ctx: StateContext<IAppStateModel>,
    action: Dash.GetDashboardFoldersFail,
  ) {
    const { error } = action;
    console.error(error);
  }

  @Action(Dash.CreateOrUpdateDashboardFolder) createOrUpdateDashboardFolder(
    ctx: StateContext<IAppStateModel>,
    action: Dash.CreateOrUpdateDashboardFolder,
  ) {
    return this.ws
      .asyncRequest$(
        'CREATE_OR_UPDATE_DASHBOARD_FOLDER',
        action.dashboardFolder,
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return ctx.dispatch(
              new Dash.CreateOrUpdateDashboardFolderSuccess(
                action.dashboardFolder,
              ),
            );
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((err) => {
          return ctx.dispatch(new Dash.CreateOrUpdateDashboardFolderFail(err));
        }),
      );
  }

  @Action(Dash.CreateOrUpdateDashboardFolderSuccess)
  createOrUpdateDashboardFolderSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Dash.CreateOrUpdateDashboardFolderSuccess,
  ) {
    const dashboardFolders = ctx.getState().appState.dashboardFolders;
    const fIdx = dashboardFolders.findIndex(
      (f) => f.id === action.dashboardFolder.id,
    );
    if (fIdx !== -1) {
      dashboardFolders[fIdx] = action.dashboardFolder;
    } else {
      dashboardFolders.push(action.dashboardFolder);
    }

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          dashboardFolders: dashboardFolders,
        }),
      }),
    );
  }

  @Action(Dash.CreateOrUpdateDashboardFolderFail)
  createOrUpdateDashboardFolderFail(
    ctx: StateContext<IAppStateModel>,
    action: Dash.CreateOrUpdateDashboardFolderFail,
  ) {
    const { error } = action;
    console.error(error);
  }

  @Action(Dash.DeleteDashboardFolder) deleteDashboardFolder(
    ctx: StateContext<IAppStateModel>,
    action: Dash.DeleteDashboardFolder,
  ) {
    return this.ws
      .asyncRequest$('DELETE_DASHBOARD_FOLDER', { folderId: action.folderId })
      .pipe(
        map((response) => {
          if (response.success) {
            return ctx.dispatch(
              new Dash.DeleteDashboardFolderSuccess(action.folderId),
            );
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((err) => {
          return ctx.dispatch(new Dash.DeleteDashboardFolderFail(err));
        }),
      );
  }

  @Action(Dash.DeleteDashboardFolderSuccess)
  deleteDashboardFolderSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Dash.DeleteDashboardFolderSuccess,
  ) {
    const dashboardFolders = ctx.getState().appState.dashboardFolders;
    const fIdx = dashboardFolders.findIndex((f) => f.id === action.folderId);
    if (fIdx === -1) return;
    const folder = dashboardFolders[fIdx];
    dashboardFolders.splice(fIdx, 1);

    const allDashboards = ctx.getState().appState.allDashboards.dashboards;
    allDashboards.forEach((dash) => {
      if (dash.dashboardFolder === action.folderId) {
        dash.dashboardFolder = folder.parent;
      }
    });

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          dashboardFolders: dashboardFolders,
          allDashboards: patch<IAllDashboardsState>({
            dashboards: allDashboards,
          }),
        }),
      }),
    );
  }

  @Action(Dash.DeleteDashboardFolderFail)
  deleteDashboardFolderFail(
    ctx: StateContext<IAppStateModel>,
    action: Dash.DeleteDashboardFolderFail,
  ) {
    const { error } = action;
    console.error(error);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////           Filter Views              /////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Action(FilterViews.SwitchFilterView) switchFilterView(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.SwitchFilterView,
  ) {
    const state = ctx.getState();
    const { filterViewId } = action;
    const currentDashboard = state.appState.currentDashboard;
    const dashboardId = currentDashboard.id;
    const widgets = state.appState.widgets;
    const widgetIdsToSetToLoading: string[] = [];
    if (!filterViewId)
      widgetIdsToSetToLoading.push(...currentDashboard.widgetIds);
    else {
      const filterView = state.appState.filterViews[filterViewId];
      widgetIdsToSetToLoading.push(
        ...filterView.filterViewItems.flatMap((fvi) =>
          fvi.widgetsAndChildFilters.map((w) => w.widgetId),
        ),
      );
    }
    widgetIdsToSetToLoading.forEach((widgetId) => {
      let widget = widgets[widgetId];
      if (!widget) return;
      widget = widget.reInitialize();
      widget.isLoading = true;
      widgets[widgetId] = widget;
    });

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets,
        }),
      }),
    );

    return this.ws
      .asyncRequest$('SWITCH_FILTER_VIEW', { filterViewId, dashboardId })
      .pipe(
        map((response: IAPIResponse<string>) => {
          if (response.success) {
            return ctx.dispatch(
              new FilterViews.SwitchFilterViewSuccess(filterViewId),
            );
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((err) => {
          return ctx.dispatch(new FilterViews.SwitchFilterViewFail(err));
        }),
      );
  }

  @Action(FilterViews.SwitchFilterViewSuccess) switchFilterViewSuccess(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.SwitchFilterViewSuccess,
  ) {
    const { filterViewId } = action;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          currentDashboard: patch<IDashV2State>({
            activeFilterViewId: filterViewId,
          }),
        }),
      }),
    );

    ctx.dispatch(new Dash.ReinitializeDashboardWidgets());
  }

  @Action(FilterViews.SwitchFilterViewFail) switchFilterViewFail(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.SwitchFilterViewFail,
  ) {
    console.error(action.error);
    ctx.dispatch(new Dash.ReinitializeDashboardWidgets());
  }

  // Create a filter view
  @Action(FilterViews.CreateFilterView) createFilterView(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.CreateFilterView,
  ) {
    const filterViewToCreate = action.filterView;
    const state = ctx.getState();
    const payload: ICreateFilterViewRequestPayload = {
      dashboardId: state.appState.currentDashboard.id,
      filterView: filterViewToCreate || null,
    };
    return this.ws.asyncRequest$('CREATE_FILTER_VIEW', payload).pipe(
      map((response: IAPIResponse<IFilterView>) => {
        if (response.success) {
          return ctx.dispatch(
            new FilterViews.CreateFilterViewSuccess(response.res),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((err) => {
        return ctx.dispatch(new FilterViews.CreateFilterViewFail(err));
      }),
    );
  }

  @Action(FilterViews.CreateFilterViewSuccess) createFilterViewSuccess(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.CreateFilterViewSuccess,
  ) {
    const state = ctx.getState();
    const filterViews = state.appState.filterViews;
    const filterView = action.filterView;
    const filterViewId = filterView.id;
    ctx.setState({
      appState: {
        ...state.appState,
        currentDashboard: {
          ...state.appState.currentDashboard,
          activeFilterViewId: filterViewId,
        },
        filterViews: {
          ...filterViews,
          [filterViewId]: {
            ...filterView,
            isEditing: true,
            isLoading: false,
            activeFilterViewItem: null,
          },
        },
      },
    });
  }

  @Action(FilterViews.CreateFilterViewFail) createFilterViewFail(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.CreateFilterViewFail,
  ) {
    console.error(action.error);
  }

  @Action(FilterViews.FetchFilterViews) fetchFilterViews(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.FetchFilterViews,
  ) {
    const dashboardId = action.dashId;

    return this.ws.asyncRequest$('FETCH_FILTER_VIEWS', { dashboardId }).pipe(
      map((response: IAPIResponse<IFetchFilterViewsResponse>) => {
        if (response.success) {
          return ctx.dispatch(
            new FilterViews.FetchFilterViewsSuccess(response.res.filterViews),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((err) => {
        return ctx.dispatch(new FilterViews.FetchFilterViewsFail(err));
      }),
    );
  }

  @Action(FilterViews.FetchFilterViewsSuccess) fetchFilterViewsSuccess(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.FetchFilterViewsSuccess,
  ) {
    const filterViews = action.filterViews;
    const filterViewsById: IFilterViews = filterViews.reduce(
      (acc, filterView) => {
        const filterViewStateReadyObject: IFilterViewState = {
          ...filterView,
          isEditing: false,
          isLoading: false,
          activeFilterViewItem: null,
        };
        acc[filterView.id] = filterViewStateReadyObject;
        return acc;
      },
      {},
    );

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterViews: filterViewsById,
        }),
      }),
    );
  }

  @Action(FilterViews.FetchFilterViewsFail) fetchFilterViewsFail(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.FetchFilterViewsFail,
  ) {
    console.error(action.error);
  }

  // Update a filter view
  @Action(FilterViews.UpdateFilterView) updateFilterView(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.UpdateFilterView,
  ) {
    const { filterView } = action;

    // Set the filter view to loading
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterViews: patch<IFilterViews>({
            [filterView.id]: patch<IFilterViewState>({
              // Using the frontend version of the filter views so we can update the UI immediately
              ...filterView,
              isLoading: true,
            }),
          }),
        }),
      }),
    );

    return this.ws.asyncRequest$('UPDATE_FILTER_VIEW', filterView).pipe(
      map((response: IAPIResponse<IFilterView>) => {
        if (response.success) {
          return ctx.dispatch(
            new FilterViews.UpdateFilterViewSuccess(response.res),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((err) => {
        return ctx.dispatch(
          new FilterViews.UpdateFilterViewFail(err, filterView.id),
        );
      }),
    );
  }

  @Action(FilterViews.UpdateFilterViewSuccess) updateFilterViewSuccess(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.UpdateFilterViewSuccess,
  ) {
    const state = ctx.getState();
    const filterViews = state.appState.filterViews;
    const filterView = action.filterView;
    const filterViewId = filterView.id;
    const activeFilterViewItem = filterViews[filterViewId].activeFilterViewItem;

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterViews: patch<IFilterViews>({
            [filterViewId]: patch<IFilterViewState>({
              ...filterView,
              isLoading: false,
              activeFilterViewItem,
            }),
          }),
        }),
      }),
    );
  }

  @Action(FilterViews.UpdateFilterViewFail) updateFilterViewFail(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.UpdateFilterViewFail,
  ) {
    const { filterViewId } = action;

    // Set the filter view to not loading
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterViews: patch<IFilterViews>({
            [filterViewId]: patch<IFilterViewState>({
              isLoading: false,
            }),
          }),
        }),
      }),
    );

    console.error(action.error);
  }

  // Delete a filter view
  @Action(FilterViews.DeleteFilterView) deleteFilterView(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.DeleteFilterView,
  ) {
    const { filterViewId } = action;
    return this.ws.asyncRequest$('DELETE_FILTER_VIEW', filterViewId).pipe(
      map((response: IAPIResponse<string>) => {
        if (response.success) {
          return ctx.dispatch(
            new FilterViews.DeleteFilterViewSuccess(response.res),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((err) => {
        return ctx.dispatch(new FilterViews.DeleteFilterViewFail(err));
      }),
    );
  }

  @Action(FilterViews.DeleteFilterViewSuccess) deleteFilterViewSuccess(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.DeleteFilterViewSuccess,
  ) {
    const state = ctx.getState();
    const filterViews = state.appState.filterViews;
    const filterViewId = action.filterViewId;
    delete filterViews[filterViewId];

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          currentDashboard: patch<IDashV2State>({
            activeFilterViewId: null,
          }),
          filterViews: patch<IFilterViews>({
            ...filterViews,
          }),
        }),
      }),
    );
  }

  @Action(FilterViews.DeleteFilterViewFail) deleteFilterViewFail(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.DeleteFilterViewFail,
  ) {
    console.error(action.error);
  }

  // Delete a filter view item
  @Action(FilterViews.DeleteFilterViewItem) deleteFilterViewItem(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.DeleteFilterViewItem,
  ) {
    const { filterViewItemId } = action;
    return this.ws
      .asyncRequest$('DELETE_FILTER_VIEW_ITEM', filterViewItemId)
      .pipe(
        map((response: IAPIResponse<string>) => {
          if (response.success) {
            return ctx.dispatch(
              new FilterViews.DeleteFilterViewItemSuccess(response.res),
            );
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((err) => {
          return ctx.dispatch(new FilterViews.DeleteFilterViewItemFail(err));
        }),
      );
  }

  @Action(FilterViews.DeleteFilterViewItemSuccess) deleteFilterViewItemSuccess(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.DeleteFilterViewItemSuccess,
  ) {
    const state = ctx.getState();
    const filterViewItemId = action.filterViewItemId;
    const filterViews = state.appState.filterViews;
    const filterViewWithFvi = Object.values(filterViews).find((filterView) => {
      return filterView.filterViewItems.find(
        (fvi) => fvi.id === filterViewItemId,
      );
    });

    // Filter the filter view item from the filter view
    const filterViewItems = filterViewWithFvi.filterViewItems.filter(
      (fvi) => fvi.id !== filterViewItemId,
    );
    filterViewWithFvi.filterViewItems = filterViewItems;

    filterViews[filterViewWithFvi.id] = filterViewWithFvi;

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterViews: patch<IFilterViews>({
            ...filterViews,
          }),
        }),
      }),
    );
  }

  @Action(FilterViews.DeleteFilterViewItemFail) deleteFilterViewItemFail(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.DeleteFilterViewItemFail,
  ) {
    console.error(action.error);
  }

  /**
   * @description Updates a filter view item.
   */
  @Action(FilterViews.UpdateFilterViewItem) updateFilterViewItem(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.UpdateFilterViewItem,
  ) {
    const state = ctx.getState();
    const { filterViewItem } = action;
    const filterViewId = filterViewItem.filterViewId;
    const filterViews = state.appState.filterViews;

    // Update the filter view item
    const filterView = filterViews[filterViewId];
    // Filter view items without the updated filter view item
    const filterViewItems = filterView.filterViewItems.filter(
      (item) => item.id !== filterViewItem.id,
    );
    // Add the updated filter view item
    filterViewItems.push(filterViewItem);
    // Update the filter view
    ctx.dispatch(
      new FilterViews.UpdateFilterView({ ...filterView, filterViewItems }),
    );
  }

  /**
   * @description Set's the active filter view item for the current dashboard.
   * Set the filterViewItem to null to clear the active filter view item.
   */
  @Action(FilterViews.SetActiveFilterViewItem) setActiveFilterViewItem(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.SetActiveFilterViewItem,
  ) {
    const { filterViewId, filterViewItemId } = action;
    const state = ctx.getState();

    // First, check if the filter view item is null.
    // If it is, then we can set the active filter view item to null
    // and the active filter view id to null.
    if (filterViewItemId === null) {
      ctx.setState(
        patch<IAppStateModel>({
          appState: patch<IAppState>({
            currentDashboard: patch<IDashV2State>({
              selectingFilterViewItemWidgets: false,
            }),
            filterViews: patch<IFilterViews>({
              [state.appState.currentDashboard.activeFilterViewId]:
                patch<IFilterViewState>({
                  activeFilterViewItem: null,
                }),
            }),
          }),
        }),
      );
    } else if (filterViewId !== null && filterViewItemId !== null) {
      // If the filter view item is not null, then we need to set the active filter view item
      // and the active filter view id. We also need to set the previous active filter view item
      // to not active.
      const filterViewItem = state.appState.filterViews[
        filterViewId
      ].filterViewItems.find((item) => item.id === filterViewItemId);

      ctx.setState(
        patch<IAppStateModel>({
          appState: patch<IAppState>({
            currentDashboard: patch<IDashV2State>({
              selectingFilterViewItemWidgets: true,
            }),
            filterViews: patch<IFilterViews>({
              [filterViewId]: patch<IFilterViewState>({
                activeFilterViewItem: filterViewItem,
              }),
            }),
          }),
        }),
      );

      // Now set the active filter view in the database
      ctx.dispatch(new FilterViews.SwitchFilterView(filterViewId));
    }
  }

  /**
   * @description Sends new filter view item widget relations to the server.
   * @param ctx
   * @param action
   */
  @Action(FilterViews.AddFilterViewItemWidgetRelationship)
  addFilterViewItemWidgetRelationship(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.AddFilterViewItemWidgetRelationship,
  ) {
    const { fviwcRelationship } = action;
    const widgetId = fviwcRelationship.widgetId;
    const state = ctx.getState();
    const widget = state.appState.widgets[widgetId];
    widget.isLoading = true;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch<IWidgetStateMap>({
            [widgetId]: widget.reInitialize(),
          }),
        }),
      }),
    );

    return this.ws
      .asyncRequest$('ADD_FILTER_VIEW_ITEM_WIDGET_RELATION', {
        fviwcRelationship,
      })
      .pipe(
        tap((response: IAPIResponse<IAddFVIWCResponse>) => {
          if (response.success) {
            return ctx.dispatch(
              new FilterViews.AddFilterViewItemWidgetRelationshipSuccess(
                response.res,
              ),
            );
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((error) =>
          ctx.dispatch(
            new FilterViews.AddFilterViewItemWidgetRelationshipFail(
              error,
              widgetId,
            ),
          ),
        ),
      );
  }

  @Action(FilterViews.AddFilterViewItemWidgetRelationshipSuccess)
  addFilterViewItemWidgetRelationSuccess(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.AddFilterViewItemWidgetRelationshipSuccess,
  ) {
    const { fviwcRelationship, previewData } =
      action.fviwcRelationshipsAndPreviewData;
    const widgetId = fviwcRelationship.widgetId;
    const state = ctx.getState();
    const currentDashboard = state.appState.currentDashboard;
    const filterViews = state.appState.filterViews;
    const activeFilterViewId = currentDashboard.activeFilterViewId;
    const activeFilterView = filterViews[activeFilterViewId];
    const activeFilterViewItem = activeFilterView.activeFilterViewItem;
    const widget = state.appState.widgets[widgetId];
    widget.isLoading = false;
    widget.setActiveFilterViewId(activeFilterViewId);

    // Add the new filter view item widget relations to the active filter view item
    activeFilterViewItem.widgetsAndChildFilters =
      activeFilterViewItem.widgetsAndChildFilters.concat(fviwcRelationship);

    // Update the state
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterViews: patch<IFilterViews>({
            [activeFilterViewId]: patch<IFilterViewState>({
              activeFilterViewItem,
              filterViewItems: updateItem<IFilterViewItem>(
                (item) => item.id === activeFilterViewItem.id,
                activeFilterViewItem,
              ),
            }),
          }),
        }),
      }),
    );
    // Update the widget's state separately because it needs access to the active filter view item
    // in state
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch<IWidgetStateMap>({
            [widgetId]: widget.reInitialize(),
          }),
        }),
      }),
    );

    let previewDataByWidgetId: {
      res: IGetCalculationPreviewsResponse;
      widgetId: string;
    } = {
      res: {
        results: previewData,
      },
      // For now, we can assume that there is only one widget in the preview data
      widgetId: widgetId,
    };

    // Dispatch the action to update the preview data
    ctx.dispatch(
      new WidgetEditor.GetCalculationPreviewsSuccess(
        previewDataByWidgetId.res,
        previewDataByWidgetId.widgetId,
      ),
    );
  }

  @Action(FilterViews.AddFilterViewItemWidgetRelationshipFail)
  addFilterViewItemWidgetRelationFail(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.AddFilterViewItemWidgetRelationshipFail,
  ) {
    const { error, widgetId } = action;
    const state = ctx.getState();
    const widget = state.appState.widgets[widgetId];
    widget.isLoading = false;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch<IWidgetStateMap>({
            [widgetId]: widget,
          }),
        }),
      }),
    );
    console.error(error);
  }

  /**
   * @description Deletes filter view item widget relationships from the server.
   * @param ctx
   * @param action
   */
  @Action(FilterViews.DeleteFilterViewItemWidgetRelationship)
  deleteFilterViewItemWidgetRelationship(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.DeleteFilterViewItemWidgetRelationship,
  ) {
    const { filterViewWidgetRelationId, widgetId } = action;
    const state = ctx.getState();
    const widget = state.appState.widgets[widgetId];
    widget.isLoading = true;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch<IWidgetStateMap>({
            [widgetId]: widget.reInitialize(),
          }),
        }),
      }),
    );

    return this.ws
      .asyncRequest$('DELETE_FILTER_VIEW_ITEM_WIDGET_RELATION', {
        fviwcRelationshipId: filterViewWidgetRelationId,
      })
      .pipe(
        tap((response: IAPIResponse<IDeleteFVIWCResponse>) => {
          if (response.success) {
            return ctx.dispatch(
              new FilterViews.DeleteFilterViewItemWidgetRelationshipSuccess(
                response.res.fviwcRelationshipId,
                widgetId,
              ),
            );
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((error) =>
          ctx.dispatch(
            new FilterViews.DeleteFilterViewItemWidgetRelationshipFail(
              error,
              widgetId,
            ),
          ),
        ),
      );
  }

  @Action(FilterViews.DeleteFilterViewItemWidgetRelationshipSuccess)
  deleteFilterViewItemWidgetRelationSuccess(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.DeleteFilterViewItemWidgetRelationshipSuccess,
  ) {
    const { filterViewWidgetRelationId, widgetId } = action;
    const state = ctx.getState();
    const currentDashboard = state.appState.currentDashboard;
    const filterViews = state.appState.filterViews;
    const activeFilterViewId = currentDashboard.activeFilterViewId;
    const activeFilterView = filterViews[activeFilterViewId];
    const activeFilterViewItem = activeFilterView.activeFilterViewItem;
    const widget = state.appState.widgets[widgetId];
    widget.isLoading = false;
    widget.setActiveFilterViewId(null);

    // Remove the deleted filter view item widget relations from the active filter view item
    activeFilterViewItem.widgetsAndChildFilters =
      activeFilterViewItem.widgetsAndChildFilters.filter(
        (fviwr) => fviwr.id !== filterViewWidgetRelationId,
      );

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterViews: patch<IFilterViews>({
            [activeFilterViewId]: patch<IFilterViewState>({
              activeFilterViewItem,
              filterViewItems: updateItem<IFilterViewItem>(
                (item) => item.id === activeFilterViewItem.id,
                activeFilterViewItem,
              ),
            }),
          }),
        }),
      }),
    );

    // Update the widget's state separately because it needs access to the active filter view item
    // in state
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch<IWidgetStateMap>({
            [widgetId]: widget.reInitialize(),
          }),
        }),
      }),
    );
  }

  @Action(FilterViews.DeleteFilterViewItemWidgetRelationshipFail)
  deleteFilterViewItemWidgetRelationFail(
    ctx: StateContext<IAppStateModel>,
    action: FilterViews.DeleteFilterViewItemWidgetRelationshipFail,
  ) {
    const { error, widgetId } = action;
    const state = ctx.getState();
    const widget = state.appState.widgets[widgetId];
    widget.isLoading = false;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch<IWidgetStateMap>({
            [widgetId]: widget.reInitialize(),
          }),
        }),
      }),
    );
    console.error(error);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////           Widget Editor             /////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  @Action(WidgetEditor.Open)
  openWidgetEditor(
    ctx: StateContext<IAppStateModel>,
    action: WidgetEditor.Open,
  ) {
    const { widgetId, dashId, dialogService, gridsterItem } = action;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgetEditor: patch<IWidgetEditorState>({
            isOpen: true,
            widgetId,
            dialogService,
          }),
        }),
      }),
    );
    const widgetCalculationIds = ctx
      .getState()
      .appState.widgets[widgetId]?.calcArray.map((calc) => calc.id);
    ctx.dispatch(
      new Widget.UnsubscribeFromCalculationData(widgetCalculationIds),
    );
    dialogService.open(WidgetEditorComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        widgetId: widgetId,
        gridsterItem,
        dashboardId: dashId,
      },
    });
  }

  @Action(WidgetEditor.OnClose) onCloseWidgetEditor(
    ctx: StateContext<IAppStateModel>,
    action: WidgetEditor.OnClose,
  ) {
    const { widgetId, destroy, updatingWidget } = action;
    const state = ctx.getState();
    const widgets = state.appState.widgets;
    const widget = widgets[widgetId];
    if (destroy) {
      delete widgets[widgetId];
    } else {
      widgets[widgetId] = widget.reInitialize();
      widgets[widgetId].isLoading = true;
    }
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgetEditor: patch<IWidgetEditorState>({
            isOpen: false,
            isLoading: false,
            widgetId: null,
          }),
          widgets: patch<IWidgetStateMap>(widgets),
        }),
      }),
    );

    // If the widget is being updated, we don't need to fetch it again because it will be fetched
    // when the update is successful
    if (!destroy && !updatingWidget) {
      ctx.dispatch(new Widget.FetchWidget(widgetId));
    }
  }

  @Action(WidgetEditor.CreateWidget) createWidget(
    ctx: StateContext<IAppStateModel>,
    action: WidgetEditor.CreateWidget,
  ) {
    const widget = action.widget;
    const dashboardId = action.dashboardId;
    const state = ctx.getState();
    ctx.setState({
      appState: {
        ...state.appState,
        widgetEditor: {
          ...state.appState.widgetEditor,
          isLoading: true,
        },
      },
    });
    return this.ws.asyncRequest$('CREATE_WIDGET', { widget, dashboardId }).pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(
            new WidgetEditor.CreateWidgetSuccess(response.res, dashboardId),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new WidgetEditor.CreateWidgetFail(e));
      }),
    );
  }

  @Action(WidgetEditor.CreateWidgetSuccess) createWidgetSuccess(
    ctx: StateContext<IAppStateModel>,
    action: WidgetEditor.CreateWidgetSuccess,
  ) {
    const widget = new WidgetStateObject(action.widget.widget, this.store);
    widget.stateRef = this.store;
    widget.isLoading = false;
    const state = ctx.getState();
    const widgets = state.appState.widgets;
    widgets[widget.id] = widget;
    const newState: IAppStateModel = {
      appState: {
        ...state.appState,
        widgetEditor: {
          ...state.appState.widgetEditor,
          isLoading: false,
        },
        widgets,
      },
    };
    if (state.appState.currentDashboard.id === action.dashId) {
      newState.appState.currentDashboard.widgetIds.push(widget.id);
      newState.appState.currentDashboard = {
        ...state.appState.currentDashboard,
      };
    }
    ctx.setState(newState);
    ctx.dispatch(new Dash.RequestCompanyDashRefresh());
    ctx.dispatch(new App.FetchDataStreams(true, [widget.id]));
    ctx.dispatch(new Widget.CreateTempCalcData([widget.groupId]));
  }

  @Action(WidgetEditor.CreateWidgetFail) createWidgetFail(
    ctx: StateContext<IAppStateModel>,
    action: WidgetEditor.CreateWidgetFail,
  ) {
    const state = ctx.getState();
    ctx.setState({
      appState: {
        ...state.appState,
        widgetEditor: {
          ...state.appState.widgetEditor,
          isLoading: false,
        },
      },
    });
    console.error(action.error);
  }

  @Action(WidgetEditor.UpdateWidget) updateWidget(
    ctx: StateContext<IAppStateModel>,
    action: WidgetEditor.UpdateWidget,
  ) {
    const widgetId = action.widgetId;
    const state = ctx.getState();
    const widgets = state.appState.widgets;
    let widget = widgets[widgetId];
    widget = widget.reInitialize();

    widget.isLoading = true;

    widgets[widget.id] = widget;
    ctx.setState({
      appState: {
        ...state.appState,
        widgets,
      },
    });
    return this.ws
      .asyncRequest$('UPDATE_WIDGET', {
        widget: widget.toStorableWidget(),
        dashboardId: widget.dashboardV2Id,
      })
      .pipe(
        map((response) => {
          if (response.success) {
            return ctx.dispatch(
              new WidgetEditor.UpdateWidgetSuccess(response.res.widget),
            );
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((e) => {
          return ctx.dispatch(new WidgetEditor.UpdateWidgetFail(e, widget.id));
        }),
      );
  }

  @Action(WidgetEditor.UpdateWidgetSuccess) updateWidgetSuccess(
    ctx: StateContext<IAppStateModel>,
    action: WidgetEditor.UpdateWidgetSuccess,
  ) {
    const widget = new WidgetStateObject(action.widget, this.store);
    widget.stateRef = this.store;
    const state = ctx.getState();
    const widgets = state.appState.widgets;
    widgets[widget.id] = widget.reInitialize();

    let fetchWidgetData = widget.calcArray.length > 0;

    if (!fetchWidgetData) {
      widget.isLoading = false;
    }
    ctx.setState({
      appState: {
        ...state.appState,
        widgets,
      },
    });

    if (fetchWidgetData) {
      ctx.dispatch(new Widget.CreateTempCalcData([widget.groupId]));
    }
    ctx.dispatch(new Dash.RequestCompanyDashRefresh());
  }

  @Action(WidgetEditor.UpdateWidgetFail) updateWidgetFail(
    ctx: StateContext<IAppStateModel>,
    action: WidgetEditor.UpdateWidgetFail,
  ) {
    const widgetId = action.widgetId;
    let stateWidget = ctx.getState().appState.widgets[widgetId];
    stateWidget = stateWidget.reInitialize();
    stateWidget.isLoading = false;
    const widgets = ctx.getState().appState.widgets;
    widgets[widgetId] = stateWidget;
    const state = ctx.getState();
    ctx.setState({
      appState: {
        ...state.appState,
        widgets,
      },
    });
    console.error(action.error);
  }

  @Action(WidgetEditor.GetCalculationPreviews) getCalculationPreviews(
    ctx: StateContext<IAppStateModel>,
    action: WidgetEditor.GetCalculationPreviews,
  ) {
    const widgetId = action.widgetId;
    const state = ctx.getState();
    let widget = state.appState.widgets[widgetId];
    const widgetCalculations = widget.calcArray.map((calc) =>
      calc.toStorableCalculation(),
    );
    const payloads: IGetCalculationPreviewsRequest = {
      calculations: widgetCalculations,
      widgetConfig: widget.config,
    };
    widget.isLoading = true;

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch<IWidgetStateMap>({
            [widgetId]: widget.reInitialize(),
          }),
        }),
      }),
    );

    return this.ws.asyncRequest$('GET_CALCULATION_PREVIEWS', payloads).pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(
            new WidgetEditor.GetCalculationPreviewsSuccess(
              response.res,
              widgetId,
            ),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(
          new WidgetEditor.GetCalculationPreviewsFail(e, widgetId),
        );
      }),
    );
  }

  @Action(WidgetEditor.GetCalculationPreviewsSuccess)
  getCalculationPreviewsSuccess(
    ctx: StateContext<IAppStateModel>,
    action: WidgetEditor.GetCalculationPreviewsSuccess,
  ) {
    const res = action.res;
    const widgetId = action.widgetId;
    const state = ctx.getState();
    let widget = state.appState.widgets[widgetId];
    widget.isLoading = false;
    const calcDataList = Object.values(res.results);
    widget.setCalcData(calcDataList, 'getCalcData');
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch<IWidgetStateMap>({
            [widgetId]: widget.reInitialize(),
          }),
        }),
      }),
    );
  }

  @Action(WidgetEditor.GetCalculationPreviewsFail) getCalculationPreviewsFail(
    ctx: StateContext<IAppStateModel>,
    action: WidgetEditor.GetCalculationPreviewsFail,
  ) {
    console.error(action.error);
    const widgetId = action.widgetId;
    const state = ctx.getState();
    let widget = state.appState.widgets[widgetId];
    widget = widget.reInitialize();
    widget.isLoading = false;
    const widgets = state.appState.widgets;
    widgets[widgetId] = widget;
    ctx.setState({
      appState: {
        ...state.appState,
        widgets: widgets,
      },
    });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////               Widget                /////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Action(Widget.FetchWidget) fetchWidget(
    ctx: StateContext<IAppStateModel>,
    action: Widget.FetchWidget,
  ) {
    const widgetId = action.widgetId;
    const state = ctx.getState();
    const widgets = state.appState.widgets;
    const widgetInState = widgets[widgetId];
    let widget: WidgetStateObject;
    if (widgetInState) {
      widget = widgets[widgetId].reInitialize();
      widget.isLoading = true;
    } else {
      widget = new WidgetStateObject(
        { id: widgetId, isLoading: true },
        this.store,
      );
    }
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch<IWidgetStateMap>({
            [widgetId]: widget,
          }),
        }),
      }),
    );
    return this.ws.asyncRequest$('GET_WIDGETS', { ids: [widgetId] }).pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(
            new Widget.FetchWidgetSuccess(response.res.widgets[widgetId]),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new Widget.FetchWidgetFail(e, widgetId));
      }),
    );
  }

  @Action(Widget.FetchWidgetSuccess) fetchWidgetSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Widget.FetchWidgetSuccess,
  ) {
    const returnedWidget = action.widget;
    const state = ctx.getState();
    const widgets = state.appState.widgets;
    let widget: WidgetStateObject;
    let fetchCalcData = false;
    if (widgets[returnedWidget.id]) {
      widget = widgets[returnedWidget.id].reInitialize(returnedWidget);
      fetchCalcData = widget.calcArray.length > 0;
    } else {
      widget = new WidgetStateObject(returnedWidget, this.store);
    }

    if (fetchCalcData) {
      widget.isLoading = true;
    }

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch<IWidgetStateMap>({
            [returnedWidget.id]: widget,
          }),
        }),
      }),
    );

    if (fetchCalcData) {
      const calculationIds = widget.calcArray.map(
        (calc) => calc.activeCalcDataId,
      );
      ctx.dispatch(
        new Widget.GetCalculationData(calculationIds, [widget.id], true),
      );
    }
  }

  @Action(Widget.FetchWidgetFail) fetchWidgetFail(
    ctx: StateContext<IAppStateModel>,
    action: Widget.FetchWidgetFail,
  ) {
    const widgetId = action.widgetId;
    const state = ctx.getState();
    const widget = state.appState.widgets[widgetId].reInitialize();
    widget.isLoading = false;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch<IWidgetStateMap>({
            [widgetId]: widget,
          }),
        }),
      }),
    );
    console.error(action.error);
  }

  @Action(Widget.PositionChange) positionChange(
    ctx: StateContext<IAppStateModel>,
    action: Widget.PositionChange,
  ) {
    const state = ctx.getState();
    const widgetId = action.widgetId;
    const position = action.position;
    const widget = state.appState.widgets[widgetId].reInitialize();
    widget.gridsterItem = position;
    const widgets = state.appState.widgets;
    widgets[widgetId] = widget;
    if (!widget) {
      throw new Error(`Widget ${widgetId} is not loaded in state`);
    }
    ctx.setState({
      appState: {
        ...state.appState,
        widgets,
      },
    });
  }

  // Delete Widget
  @Action(Widget.DeleteWidget) softDelete(
    ctx: StateContext<IAppStateModel>,
    action: Widget.DeleteWidget,
  ) {
    const widgetId = action.widgetId;
    const state = ctx.getState();
    const widget = state.appState.widgets[widgetId].reInitialize();
    widget.isLoading = true;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch<IWidgetStateMap>({
            [widgetId]: widget,
          }),
        }),
      }),
    );

    return this.ws.asyncRequest$('DELETE_WIDGET', { id: widgetId }).pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(new Widget.DeleteWidgetSuccess(widgetId));
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new Widget.DeleteWidgetFail(e, widgetId));
      }),
    );
  }

  @Action(Widget.DeleteWidgetSuccess) softDeleteSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Widget.DeleteWidgetSuccess,
  ) {
    const state = ctx.getState();
    const widgetId = action.widgetId;
    // Remove widget id from dashboard and delete from state
    const dashboard = state.appState.currentDashboard;
    const widgetIds = dashboard.widgetIds.filter((id) => id !== widgetId);
    dashboard.widgetIds = widgetIds;
    const widgets = state.appState.widgets;
    delete widgets[widgetId];
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          currentDashboard: dashboard,
          widgets,
        }),
      }),
    );
    ctx.dispatch(new Dash.RequestCompanyDashRefresh());
    ctx.dispatch(new FilterViews.FetchFilterViews(dashboard.id));
  }

  @Action(Widget.DeleteWidgetFail) softDeleteFail(
    ctx: StateContext<IAppStateModel>,
    action: Widget.DeleteWidgetFail,
  ) {
    const widgetId = action.widgetId;
    const state = ctx.getState();
    const widget = state.appState.widgets[widgetId].reInitialize();
    widget.isLoading = false;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch<IWidgetStateMap>({
            [widgetId]: widget,
          }),
        }),
      }),
    );
    // No loading state for this action
    console.error(action.error);
  }

  @Action(Widget.GetCalculationData) getCalculationData(
    ctx: StateContext<IAppStateModel>,
    action: Widget.GetCalculationData,
  ) {
    const { calculationIds, widgetIds, loading, getModalData, shareToken } =
      action;
    // Get rid of any calculation ids that are undefined
    const filteredCalcIds = calculationIds.filter((id) => id !== undefined);
    const state = ctx.getState();
    const widgets = state.appState.widgets;
    const dashboardWidgetIds = state.appState.currentDashboard?.widgetIds;

    let widgetIdsToUse = widgetIds;

    if (!dashboardWidgetIds && !widgetIds?.length) {
      return;
    }
    if (!widgetIds?.length && dashboardWidgetIds) {
      widgetIdsToUse = dashboardWidgetIds;
    }

    const includeModalData: IBooleanMap = {};
    for (const widgetId of widgetIdsToUse) {
      const widget = widgets[widgetId].reInitialize();
      widget.isLoading = loading;
      widgets[widgetId] = widget;

      const widgetCalculations = widget.calcArray.map(
        (calc) => calc.activeCalcDataId,
      );
      if (
        widget.widgetType === 'table' ||
        widget.widgetType === 'dynamicString'
      ) {
        for (const calcId of widgetCalculations) {
          includeModalData[calcId] = true;
        }
      } else {
        for (const calcId of widgetCalculations) {
          includeModalData[calcId] = false;
        }
      }
    }

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets,
        }),
      }),
    );

    // If we are getting modal data, we need to include all calculations
    if (getModalData) {
      for (const calcId of filteredCalcIds) {
        includeModalData[calcId] = true;
      }
    }
    const payload: IGetCalculationDataRequestV2 = {
      subscribe: true,
      calculation_uuids: filteredCalcIds,
      include_modal_data: includeModalData,
    };
    this.getCalculationDataRequestBuffer.push({
      req: payload,
      token: shareToken,
      widgetIds: widgetIds,
    });
    this.getCalculationDataSource$.next(null);
  }

  private processGetCalculationDataRequestBuffer() {
    if (!this.getCalculationDataRequestBuffer.length) {
      return;
    }
    const payload: IGetCalculationDataRequestV2 = {
      subscribe: true,
      calculation_uuids: [],
      include_modal_data: {},
    };
    let widgetIds: string[] = [];
    let shareToken;
    for (let req of this.getCalculationDataRequestBuffer) {
      payload.calculation_uuids = payload.calculation_uuids.concat(
        req.req.calculation_uuids,
      );
      payload.include_modal_data = {
        ...payload.include_modal_data,
        ...req.req.include_modal_data,
      };
      shareToken = req.token;
      widgetIds = widgetIds.concat(req.widgetIds);
    }
    function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    }
    payload.calculation_uuids = payload.calculation_uuids.filter(onlyUnique);
    this.getCalculationDataRequestBuffer = [];
    let reqObservable$: Observable<IAPIResponse<IGetCalculationDataResponse[]>>;
    if (!!shareToken) {
      reqObservable$ = this.ws.unAuthenticatedAsyncRequest$(
        'GET_CALCULATION_DATA_V2',
        payload,
        shareToken,
      );
    } else {
      reqObservable$ = this.ws.asyncRequest$(
        'GET_CALCULATION_DATA_V2',
        payload,
      );
    }
    reqObservable$
      .pipe(
        map((response) => {
          if (response.success) {
            return this.store.dispatch(
              new Widget.GetCalculationDataSuccess(widgetIds, response.res),
            );
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((e) => {
          return this.store.dispatch(
            new Widget.GetCalculationDataFail(e, widgetIds),
          );
        }),
      )
      .subscribe();
  }

  @Action(Widget.GetCalculationDataSuccess) getCalculationDataSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Widget.GetCalculationDataSuccess,
  ) {
    const state = ctx.getState();
    const widgets = state.appState.widgets;
    const widgetIds = action.widgetIds;
    const widgetsToUpdate: string[] = [];
    if (!widgetIds?.length) {
      if (!state.appState?.currentDashboard) {
        return;
      }
      widgetsToUpdate.push(...state.appState.currentDashboard.widgetIds);
    } else {
      widgetsToUpdate.push(...widgetIds);
    }

    for (const widgetId of widgetsToUpdate) {
      let widget = widgets[widgetId];
      widget = widget.reInitialize();
      widget.isLoading = false;
      const calculationData = action.res;
      const widgetCalculations = widget.calculations;
      Object.values(widgetCalculations).forEach((calc) => {
        calc.setCalcData(calculationData, 'getCalcData');
      });
      widgets[widgetId] = widget;
    }
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch(widgets),
        }),
      }),
    );
  }

  @Action(Widget.GetCalculationDataFail) getCalculationDataFail(
    ctx: StateContext<IAppStateModel>,
    action: Widget.GetCalculationDataFail,
  ) {
    console.error(action.error);
    const state = ctx.getState();
    const widgetIds = action.widgetIds;
    const widgets = state.appState.widgets;

    for (const widgetId of widgetIds) {
      let widget = widgets[widgetId];
      widget = widget.reInitialize();
      widget.isLoading = false;
      widgets[widgetId] = widget;
    }
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch(widgets),
        }),
      }),
    );
  }

  @Action(Widget.SubscribeToCalculationDataUpdates)
  subscribeToCalculationDataUpdates(
    ctx: StateContext<IAppStateModel>,
    action: Widget.SubscribeToCalculationDataUpdates,
  ) {
    const shareToken = ctx.getState().appState?.currentDashboard?.shareToken;
    this.subscribeToCalculationsRequestBuffer.push({
      calcIds: action.calcDataIds,
      shareToken,
    });
    this.subscribeToCalculationsSource$.next(null);
  }

  @Action(Widget.SubscribeToCalculationDataUpdatesSuccess)
  subscribeToCalculationDataUpdatesSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Widget.SubscribeToCalculationDataUpdatesSuccess,
  ) {
    // There's not much to do here yet.
  }

  processSubscribeToCalculationsRequestBuffer() {
    if (!this.subscribeToCalculationsRequestBuffer.length) {
      return;
    }
    let shareToken;
    let calculationIds: string[] = [];
    for (let req of this.subscribeToCalculationsRequestBuffer) {
      calculationIds = calculationIds.concat(req.calcIds);
      shareToken = req.shareToken;
    }
    this.subscribeToCalculationsRequestBuffer = [];
    let reqObservable$: Observable<IAPIResponse<boolean>>;
    if (shareToken) {
      reqObservable$ = this.ws.unAuthenticatedAsyncRequest$(
        'SUBSCRIBE_TO_CALCULATIONS',
        { calculationUuids: calculationIds },
        shareToken,
      );
    } else {
      reqObservable$ = this.ws.asyncRequest$('SUBSCRIBE_TO_CALCULATIONS', {
        calculationUuids: calculationIds,
      });
    }
    reqObservable$
      .pipe(
        take(1),
        map((response) => {
          if (!response.success) {
            throw new Error(response.error);
          } else {
            return this.store.dispatch(
              new Widget.SubscribeToCalculationDataUpdatesSuccess(),
            );
          }
        }),
      )
      .subscribe();
  }

  @Action(Widget.UnsubscribeFromCalculationData) unsubscribeFromCalculationData(
    ctx: StateContext<IAppStateModel>,
    action: Widget.UnsubscribeFromCalculationData,
  ) {
    const { calculationIds, shareToken } = action;
    this.unsubscribeFromCalculationsRequestBuffer.push({
      calcIds: calculationIds,
      shareToken,
    });
    this.unsubscribeFromCalculationsSource$.next(null);
  }
  processUnsubscribeFromCalculationsRequestBuffer() {
    if (!this.unsubscribeFromCalculationsRequestBuffer.length) {
      return;
    }
    let shareToken;
    let calculationIds: string[] = [];
    for (let req of this.unsubscribeFromCalculationsRequestBuffer) {
      calculationIds = calculationIds.concat(req.calcIds);
      shareToken = req.shareToken;
    }
    this.unsubscribeFromCalculationsRequestBuffer = [];
    let reqObservable$: Observable<IAPIResponse<string[]>>;
    if (!!shareToken) {
      reqObservable$ = this.ws.unAuthenticatedAsyncRequest$(
        'UNSUBSCRIBE_FROM_CALCULATIONS',
        calculationIds,
        shareToken,
      );
    } else {
      reqObservable$ = this.ws.asyncRequest$(
        'UNSUBSCRIBE_FROM_CALCULATIONS',
        calculationIds,
      );
    }

    reqObservable$
      .pipe(
        map((response) => {
          if (response.success) {
            return this.store.dispatch(
              new Widget.UnsubscribeFromCalculationDataSuccess(response.res),
            );
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((e) => {
          return this.store.dispatch(
            new Widget.UnsubscribeFromCalculationDataFail(e),
          );
        }),
      )
      .subscribe();
  }

  @Action(Widget.UnsubscribeFromCalculationDataSuccess)
  unsubscribeFromCalculationDataSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Widget.UnsubscribeFromCalculationDataSuccess,
  ) {
    const { calculationIds } = action;
    // There's not much to do here yet.
  }

  @Action(Widget.UnsubscribeFromCalculationDataFail)
  unsubscribeFromCalculationDataFail(
    ctx: StateContext<IAppStateModel>,
    action: Widget.UnsubscribeFromCalculationDataFail,
  ) {
    console.error(action.error);
  }

  @Action(Widget.Edit) editWidget(
    ctx: StateContext<IAppStateModel>,
    action: Widget.Edit,
  ) {
    const state = ctx.getState();
    const widgetId = action.widgetId;
    const dialog = action.dialogService;
    const widgetExists = !!state.appState.widgets[widgetId];
    if (!widgetExists) {
      throw new Error(`Widget ${widgetId} is not loaded in state`);
    }
    const widget = state.appState.widgets[widgetId].reInitialize();
    const dashId = widget.dashboardV2Id;
    ctx.dispatch(new WidgetEditor.Open(dialog, widgetId, dashId));
  }

  @Action(Widget.CalcDataUpdate) widgetCalcDataUpdate(
    ctx: StateContext<IAppStateModel>,
    action: Widget.CalcDataUpdate,
  ) {
    const state = ctx.getState();
    const widgetId = action.widgetId;
    let widget = state.appState.widgets[widgetId];
    if (!widget) {
      return;
    } else {
      widget.isLoading = false;
      widget = widget.reInitialize();
    }
    try {
      widget.setCalcData(action.calcData, 'dataUpdate');
    } catch (e) {
      console.error('Error setting calc data for widget ' + widgetId);
      console.error(e);
      return;
    }
    ctx.setState({
      appState: {
        ...state.appState,
        widgets: {
          ...state.appState.widgets,
          [widgetId]: widget,
        },
      },
    });
  }

  @Action(Widget.SetWidget) setWidget(
    ctx: StateContext<IAppStateModel>,
    action: Widget.SetWidget,
  ) {
    const storableWidget = action.widget;
    const state = ctx.getState();
    const existingWidget = state.appState.widgets[storableWidget.id];
    let widget;
    if (existingWidget) {
      widget = existingWidget.reInitialize(storableWidget);
    } else {
      widget = new WidgetStateObject(storableWidget, this.store);
    }
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch<IWidgetStateMap>({
            [widget.id]: widget,
          }),
        }),
      }),
    );
  }

  @Action(Widget.CreateCopy) createCopy(
    ctx: StateContext<IAppStateModel>,
    action: Widget.CreateCopy,
  ) {
    const state = ctx.getState();
    const { widgetId, dashId, gridsterItem, newGroup, title } = action;
    const widgets = state.appState.widgets;
    const widget = widgets[widgetId];

    widget.reInitialize();
    widget.isLoading = true;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch(widgets),
        }),
      }),
    );
    return this.ws
      .asyncRequest$('CREATE_WIDGET_COPY', {
        widget_id: widgetId,
        dashboard_id: dashId,
        gridster_item: gridsterItem,
        new_group: newGroup,
        title,
      })
      .pipe(
        map((response) => {
          if (response.success) {
            return ctx.dispatch(
              new Widget.CreateCopySuccess(response.res.widget, widgetId),
            );
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((e) => {
          return ctx.dispatch(new Widget.CreateCopyFail(e, widgetId));
        }),
      );
  }

  @Action(Widget.CreateCopySuccess) createCopySuccess(
    ctx: StateContext<IAppStateModel>,
    action: Widget.CreateCopySuccess,
  ) {
    const state = ctx.getState();
    const sourceWidgetId = action.sourceWidgetId;
    const newWidget = action.widget;
    const dashId = newWidget.dashboardV2Id;
    const groupId = newWidget.groupId;
    const widgets = state.appState.widgets;
    // We're not aware of which widget was the source, so we stop loading on all widgets.
    // If this becomes a problem, the source widget id can be passed from the backend.
    const widgetsOfSameGroup = Object.values(widgets).filter(
      (w) => w.groupId === groupId,
    );
    widgetsOfSameGroup.forEach((w) => {
      const _w = w.reInitialize();
      _w.isLoading = false;
      widgets[_w.id] = _w;
    });
    const sourceWidget = widgets[sourceWidgetId].reInitialize();
    sourceWidget.isLoading = false;
    widgets[sourceWidgetId] = sourceWidget;
    const currentDashboard = { ...state.appState.currentDashboard };
    if (currentDashboard && currentDashboard.id === dashId) {
      currentDashboard.widgetIds.push(newWidget.id);
    }
    const widgetObject = new WidgetStateObject(newWidget, this.store);
    widgetObject.isLoading = true;
    widgets[widgetObject.id] = widgetObject;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets,
          currentDashboard: currentDashboard,
        }),
      }),
    );

    ctx.dispatch(new Dash.RequestCompanyDashRefresh());
    ctx.dispatch(new Widget.CreateTempCalcData([widgetObject.groupId]));
    ctx.dispatch(new FilterVariable.GetFilterVariablesState(dashId));
    ctx.dispatch(new Widget.FetchWidgetSuccess(newWidget));
  }

  @Action(Widget.CreateCopyFail) createCopyFail(
    ctx: StateContext<IAppStateModel>,
    action: Widget.CreateCopyFail,
  ) {
    const state = ctx.getState();
    const widgetId = action.widgetId;
    const widgets = state.appState.widgets;
    const widget = widgets[widgetId];
    widget.reInitialize();
    widget.isLoading = true;
    ctx.setState({
      appState: {
        ...state.appState,
        widgets,
      },
    });
    console.error('Error creating widget copy for widget ' + widgetId);
    console.error(action.error);
  }

  /**
   * @description put the widgets on the current dashboard into a highlighting state
   */
  @Action(Widget.SetWidgetHighlightState) setWidgetHighlightState(
    ctx: StateContext<IAppStateModel>,
    action: Widget.SetWidgetHighlightState,
  ) {
    const state = ctx.getState();
    const { widgetHighlighStates } = action;
    const widgets = state.appState.widgets;
    const currentDashboardWidgetIds = state.appState.currentDashboard.widgetIds;

    // If the widgetHighlighStates is null, we reset the highlight
    // state of all widgets on the current dashboard.
    if (!widgetHighlighStates) {
      currentDashboardWidgetIds.forEach((widgetId) => {
        const widget = widgets[widgetId];
        widget.reInitialize();
        widget.highlightState = 'normal';
        widgets[widgetId] = widget;
      });
      ctx.setState(
        patch<IAppStateModel>({
          appState: patch<IAppState>({
            widgets,
          }),
        }),
      );
      return;
    }

    const widgetIds = Object.keys(widgetHighlighStates);

    widgetIds.forEach((widgetId) => {
      const widget = widgets[widgetId];
      widget.reInitialize();
      widget.highlightState = widgetHighlighStates[widgetId];
      widgets[widgetId] = widget;
    });
    ctx.setState({
      appState: {
        ...state.appState,
        widgets,
      },
    });
  }

  @Action(Widget.ToggleSelectedState) toggleSelectedState(
    ctx: StateContext<IAppStateModel>,
    action: Widget.ToggleSelectedState,
  ) {
    const state = ctx.getState();
    const widgetId = action.widgetId;
    const widgets = state.appState.widgets;
    const widget = widgets[widgetId];
    if (widget.highlightState === 'selected') {
      widget.highlightState = 'unselected';
    } else {
      widget.highlightState = 'selected';
    }

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: patch<IWidgetStateMap>({
            [widgetId]: widget.reInitialize(),
          }),
        }),
      }),
    );
  }

  @Action(Widget.CreateTempCalcData) createTempCalcData(
    ctx: StateContext<IAppStateModel>,
    action: Widget.CreateTempCalcData,
  ) {
    const state = ctx.getState();
    const widgets = state.appState.widgets;
    const { groupIds } = action;
    const widgetsInGroup = Object.values(widgets).filter((w) =>
      groupIds.includes(w.groupId),
    );
    widgetsInGroup.forEach((w) => {
      const widget = w.reInitialize();
      widget.isLoading = true;
      widgets[w.id] = widget;
    });
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: widgets,
        }),
      }),
    );

    const activeCalcIds = widgetsInGroup.flatMap((w) => {
      return w.calcArray.map((calc) => calc.activeCalcDataId);
    });

    // Wait for the subscription confirmation before sending the request
    this.actions$
      .pipe(
        ofActionCompleted(Widget.SubscribeToCalculationDataUpdatesSuccess),
        take(1),
      )
      .subscribe(() => {
        let req: Observable<IAPIResponse<any>>;
        if (state.appState.currentDashboard?.shareToken) {
          req = this.ws.unAuthenticatedAsyncRequest$(
            'CREATE_TEMP_CALC_DATA',
            { widgetGroupIds: groupIds },
            state.appState.currentDashboard?.shareToken,
          );
        } else {
          req = this.ws.asyncRequest$('CREATE_TEMP_CALC_DATA', {
            widgetGroupIds: groupIds,
          });
        }
        req
          .pipe(
            take(1),
            map((response) => {
              if (response.success) {
                return ctx.dispatch(
                  new Widget.CreateTempCalcDataSuccess(response.res, groupIds),
                );
              } else {
                throw new Error(response.error);
              }
            }),
            catchError((e) => {
              return ctx.dispatch(
                new Widget.CreateTempCalcDataFail(e, groupIds),
              );
            }),
          )
          .subscribe();
      });

    // Subscribe to the calculation data updates
    ctx.dispatch(new Widget.SubscribeToCalculationDataUpdates(activeCalcIds));
  }

  @Action(Widget.CreateTempCalcDataSuccess) createTempCalcDataSuccess(
    ctx: StateContext<IAppStateModel>,
    action: Widget.CreateTempCalcDataSuccess,
  ) {
    // const calcDataIds = Object.keys(action.calcPreviewData.results);
    // const calcData = Object.values(action.calcPreviewData.results);
    const groupIds = action.groupIds;
    const state = ctx.getState();
    const widgets = state.appState.widgets;
    const widgetsInGroups = Object.values(widgets).filter((w) =>
      groupIds.includes(w.groupId),
    );

    const calcDataIds = [];
    widgetsInGroups.forEach((w) => {
      const widget = w;
      widget.isLoading = true;
      widgets[w.id] = widget.reInitialize();
      calcDataIds.push(
        ...widget.calcArray.map((calc) => calc.activeCalcDataId),
      );
    });
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: widgets,
          filterVariableState: patch({
            isLoading: false,
          }),
        }),
      }),
    );
  }

  @Action(Widget.CreateTempCalcDataFail) createTempCalcDataFail(
    ctx: StateContext<IAppStateModel>,
    action: Widget.CreateTempCalcDataFail,
  ) {
    const state = ctx.getState();
    const widgets = state.appState.widgets;
    const { groupIds } = action;
    const widgetsInGroup = Object.values(widgets).filter((w) =>
      groupIds.includes(w.groupId),
    );
    widgetsInGroup.forEach((w) => {
      const widget = w.reInitialize();
      widget.isLoading = false;
      widgets[w.id] = widget;
    });
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          widgets: widgets,
        }),
      }),
    );
    console.error('Error creating temp calc data');
    console.error(action.error);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////               Filter Variable                ///////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Action(FilterVariable.Create) createFilterVariable(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.Create,
  ) {
    const filterVariable = action.filterVariable;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterVariableState: patch<IFilterVariableState>({
            isLoading: true,
          }),
        }),
      }),
    );

    return this.ws.asyncRequest$('CREATE_FILTER_VARIABLE', filterVariable).pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(new FilterVariable.CreateSuccess(response.res));
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new FilterVariable.CreateFail(e));
      }),
    );
  }

  @Action(FilterVariable.CreateSuccess) createFilterVariableSuccess(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.CreateSuccess,
  ) {
    const filterVariable = action.filterVariable;
    const state = ctx.getState();
    const filterVariablesState = state.appState.filterVariableState;
    filterVariablesState.isLoading = false;
    filterVariablesState.filterVariables.push(filterVariable);
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterVariableState: patch<IFilterVariableState>({
            ...filterVariablesState,
          }),
        }),
      }),
    );
  }

  @Action(FilterVariable.CreateFail) createFilterVariableFail(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.CreateFail,
  ) {
    const state = ctx.getState();
    const filterVariablesState = state.appState.filterVariableState;
    filterVariablesState.isLoading = false;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterVariableState: patch<IFilterVariableState>({
            ...filterVariablesState,
          }),
        }),
      }),
    );
    console.error(action.error);
  }

  @Action(FilterVariable.GetAll) getAllFilterVariables(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.GetAll,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterVariableState: patch<IFilterVariableState>({
            isLoading: true,
          }),
        }),
      }),
    );

    return this.ws.asyncRequest$('GET_FILTER_VARIABLES').pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(
            new FilterVariable.GetAllSuccess(response.res.filterVariables),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new FilterVariable.GetAllFail(e));
      }),
    );
  }

  @Action(FilterVariable.GetAllSuccess) getAllFilterVariablesSuccess(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.GetAllSuccess,
  ) {
    const filterVariables = action.filterVariables;
    const state = ctx.getState();
    const filterVariablesState = { ...state.appState.filterVariableState };
    filterVariablesState.isLoading = false;
    filterVariablesState.filterVariables = filterVariables;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterVariableState: patch<IFilterVariableState>({
            ...filterVariablesState,
          }),
        }),
      }),
    );
  }

  @Action(FilterVariable.GetAllFail) getAllFilterVariablesFail(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.GetAllFail,
  ) {
    const state = ctx.getState();
    const filterVariablesState = state.appState.filterVariableState;
    filterVariablesState.isLoading = false;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterVariableState: patch<IFilterVariableState>({
            ...filterVariablesState,
          }),
        }),
      }),
    );
    console.error(action.error);
  }

  @Action(FilterVariable.Delete) deleteFilterVariable(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.Delete,
  ) {
    const filterVariableId = action.filterVariableId;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterVariableState: patch<IFilterVariableState>({
            isLoading: true,
          }),
        }),
      }),
    );

    return this.ws
      .asyncRequest$('DELETE_FILTER_VARIABLE', { id: filterVariableId })
      .pipe(
        map((response) => {
          if (response.success) {
            return ctx.dispatch(
              new FilterVariable.DeleteSuccess(filterVariableId),
            );
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((e) => {
          return ctx.dispatch(new FilterVariable.DeleteFail(e));
        }),
      );
  }

  @Action(FilterVariable.DeleteSuccess) deleteFilterVariableSuccess(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.DeleteSuccess,
  ) {
    const filterVariableId = action.filterVariableId;
    const state = ctx.getState();
    const filterVariablesState = { ...state.appState.filterVariableState };
    filterVariablesState.isLoading = false;
    filterVariablesState.filterVariables =
      filterVariablesState.filterVariables.filter(
        (f) => f.id !== filterVariableId,
      );
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterVariableState: patch<IFilterVariableState>({
            ...filterVariablesState,
          }),
        }),
      }),
    );
  }

  @Action(FilterVariable.DeleteFail) deleteFilterVariableFail(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.DeleteFail,
  ) {
    const state = ctx.getState();
    const filterVariablesState = state.appState.filterVariableState;
    filterVariablesState.isLoading = false;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterVariableState: patch<IFilterVariableState>({
            ...filterVariablesState,
          }),
        }),
      }),
    );
    console.error(action.error);
  }

  @Action(FilterVariable.Update) updateFilterVariable(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.Update,
  ) {
    const filterVariable = action.filterVariable;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterVariableState: patch<IFilterVariableState>({
            isLoading: true,
          }),
        }),
      }),
    );

    return this.ws.asyncRequest$('UPDATE_FILTER_VARIABLE', filterVariable).pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(new FilterVariable.UpdateSuccess(response.res));
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new FilterVariable.UpdateFail(e));
      }),
    );
  }

  @Action(FilterVariable.UpdateSuccess) updateFilterVariableSuccess(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.UpdateSuccess,
  ) {
    const updatedFilterVariable = action.filterVariable;
    const state = ctx.getState();
    const filterVariablesState = { ...state.appState.filterVariableState };
    filterVariablesState.isLoading = false;
    filterVariablesState.filterVariables =
      filterVariablesState.filterVariables.map((f) =>
        f.id === updatedFilterVariable.id ? updatedFilterVariable : f,
      );
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterVariableState: patch<IFilterVariableState>({
            ...filterVariablesState,
          }),
        }),
      }),
    );
  }

  @Action(FilterVariable.UpdateFail) updateFilterVariableFail(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.UpdateFail,
  ) {
    const state = ctx.getState();
    const filterVariablesState = state.appState.filterVariableState;
    filterVariablesState.isLoading = false;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterVariableState: patch<IFilterVariableState>({
            ...filterVariablesState,
          }),
        }),
      }),
    );
    console.error(action.error);
  }

  @Action(FilterVariable.SetFilterVariablesState) setFilterVariableState(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.SetFilterVariablesState,
  ) {
    const { dashboardId, filterVariableState, linkId, defaultState } = action;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch({
          filterVariableState: patch({
            isLoading: true,
          }),
        }),
      }),
    );

    let req: Observable<
      IAPIResponse<{
        filterVariableState: IFilterVariableBackendState;
      }>
    >;
    if (ctx.getState().appState.currentDashboard?.shareToken) {
      req = this.ws.unAuthenticatedAsyncRequest$(
        'SET_FILTER_VARIABLES_STATE',
        {
          state: filterVariableState,
          dashboardId,
          linkId,
          defaultState,
        },
        ctx.getState().appState.currentDashboard.shareToken,
      );
    } else {
      req = this.ws.asyncRequest$('SET_FILTER_VARIABLES_STATE', {
        state: filterVariableState,
        dashboardId,
        linkId,
        defaultState,
      });
    }
    return req.pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(
            new FilterVariable.SetFilterVariablesStateSuccess(
              response.res.filterVariableState,
            ),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new FilterVariable.SetFilterVariablesStateFail(e));
      }),
    );
  }

  @Action(FilterVariable.SetFilterVariablesStateSuccess)
  async setFilterVariableStateSuccess(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.SetFilterVariablesStateSuccess,
  ) {
    const { filterVariableState } = action;

    // First, set the new filter variable state in the store
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterVariableState: patch<IFilterVariableState>({
            currentFilterVariableState: patch<IDashboardFilterVariableState>({
              state: filterVariableState,
            }),
          }),
        }),
      }),
    );

    // Then, reinitialize all widgets on the current dashboard
    const state = ctx.getState();
    const widgets = state.appState.widgets;
    const currentDashboard = state.appState.currentDashboard;
    if (!currentDashboard) return;
    const currentDashboardWidgetIds = currentDashboard.widgetIds;
    if (!filterVariableState) {
      await Promise.all(
        currentDashboardWidgetIds.map(async (widgetId) => {
          const widget = widgets[widgetId].reInitialize();
          widgets[widgetId] = widget;
        }),
      );
      ctx.setState(
        patch<IAppStateModel>({
          appState: patch<IAppState>({
            widgets: patch<IWidgetStateMap>({
              ...widgets,
            }),
            filterVariableState: patch({
              isLoading: false,
            }),
          }),
        }),
      );
      return;
    }

    const calcIdsToCheck =
      filterVariableState?.dashboardVariableStateToCalc.map(
        (v) => v.calculationUuid || v.childFilterId,
      ) ?? [];

    const widgetGroupsToGetTempCalcData = [];
    await Promise.all(
      currentDashboardWidgetIds.map(async (widgetId) => {
        const widget = widgets[widgetId];
        const caresAboutWidget = calcIdsToCheck.some((id) =>
          widget.caresAboutDataId(id),
        );

        if (caresAboutWidget) {
          widgetGroupsToGetTempCalcData.push(widget.groupId);
        }
      }),
    );

    ctx.dispatch(new Widget.CreateTempCalcData(widgetGroupsToGetTempCalcData));
  }

  @Action(FilterVariable.SetFilterVariablesStateFail)
  setFilterVariableStateFail(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.SetFilterVariablesStateFail,
  ) {
    console.error(action.error);
  }

  @Action(FilterVariable.GetFilterVariablesState) getFilterVariableState(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.GetFilterVariablesState,
  ) {
    const { dashboardId, linkId, token } = action;

    // Determine which WS channel to use
    const isShared = !!token;

    const payload = { dashboardId, linkId };

    let reqObservable$: Observable<
      IAPIResponse<IGetFilterVariableStateResponse>
    >;
    if (isShared) {
      reqObservable$ = this.ws.unAuthenticatedAsyncRequest$(
        'GET_FILTER_VARIABLES_STATE',
        payload,
        token,
      );
    } else {
      reqObservable$ = this.ws.asyncRequest$(
        'GET_FILTER_VARIABLES_STATE',
        payload,
      );
    }

    return reqObservable$.pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(
            new FilterVariable.GetFilterVariablesStateSuccess(response.res),
          );
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new FilterVariable.GetFilterVariablesStateFail(e));
      }),
    );
  }

  @Action(FilterVariable.GetFilterVariablesStateSuccess)
  getFilterVariableStateSuccess(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.GetFilterVariablesStateSuccess,
  ) {
    const { filterVariableResponse } = action;
    const state = ctx.getState().appState;

    const newFilterVariables = filterVariableResponse.filterVariables;
    const filterVariables = [
      ...state.filterVariableState.filterVariables.filter(
        (f) => !newFilterVariables.some((nf) => nf.id === f.id),
      ),
      ...newFilterVariables,
    ];

    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          filterVariableState: patch<IFilterVariableState>({
            currentFilterVariableState: {
              state: filterVariableResponse.filterVariableState,
              linkBaseState: filterVariableResponse.linkBaseState,
              filterVariables: newFilterVariables,
            },
            filterVariables: filterVariables,
          }),
        }),
      }),
    );

    // If there is a current dashboard, reinitialize all widgets on it
    const currentDashboard = state.currentDashboard;
    if (currentDashboard) {
      const widgets = state.widgets;
      const currentDashboardWidgetIds = currentDashboard.widgetIds;
      for (const widgetId of currentDashboardWidgetIds) {
        const widget = widgets[widgetId].reInitialize();
        widgets[widgetId] = widget;
      }
      ctx.setState(
        patch<IAppStateModel>({
          appState: patch<IAppState>({
            widgets: patch<IWidgetStateMap>({
              ...widgets,
            }),
          }),
        }),
      );
    }
  }

  @Action(FilterVariable.GetFilterVariablesStateFail)
  getFilterVariableStateFail(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.GetFilterVariablesStateFail,
  ) {
    console.error(action.error);
  }

  @Action(FilterVariable.ResetFilterVariablesState) resetFilterVariableState(
    ctx: StateContext<IAppStateModel>,
    action: FilterVariable.ResetFilterVariablesState,
  ) {
    const { dashboardId } = action;

    return this.ws
      .asyncRequest$('RESET_FILTER_VARIABLES_STATE', { dashboardId })
      .pipe(
        map((response) => {
          if (response.success) {
            return ctx.dispatch(
              new FilterVariable.SetFilterVariablesStateSuccess(
                response.res.filterVariableState,
              ),
            );
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((e) => {
          return ctx.dispatch(
            new FilterVariable.SetFilterVariablesStateFail(e),
          );
        }),
      );
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////               Share Links                ///////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Action(ShareLink.GetAll) getAllShareLinks(
    ctx: StateContext<IAppStateModel>,
  ) {
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          shareLinkState: patch<IShareLinkState>({
            isLoading: true,
          }),
        }),
      }),
    );

    return this.ws.asyncRequest$('GET_SHARE_LINKS').pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(new ShareLink.GetAllSuccess(response.res.links));
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new ShareLink.GetAllFail(e));
      }),
    );
  }

  @Action(ShareLink.GetAllSuccess) getAllShareLinksSuccess(
    ctx: StateContext<IAppStateModel>,
    action: ShareLink.GetAllSuccess,
  ) {
    const shareLinks = action.shareLinks;
    const state = ctx.getState();
    const shareLinkState = state.appState.shareLinkState;
    shareLinkState.isLoading = false;
    shareLinkState.shareLinks = shareLinks;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          shareLinkState: patch<IShareLinkState>({
            ...shareLinkState,
          }),
        }),
      }),
    );
  }

  @Action(ShareLink.GetAllFail) getAllShareLinksFail(
    ctx: StateContext<IAppStateModel>,
    action: ShareLink.GetAllFail,
  ) {
    const state = ctx.getState();
    const shareLinkState = state.appState.shareLinkState;
    shareLinkState.isLoading = false;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          shareLinkState: patch<IShareLinkState>({
            ...shareLinkState,
          }),
        }),
      }),
    );
    console.error(action.error);
  }

  @Action(ShareLink.Save) saveShareLink(
    ctx: StateContext<IAppStateModel>,
    action: ShareLink.Save,
  ) {
    const shareLink = action.shareLink;

    return this.ws.asyncRequest$('SAVE_SHARE_LINK', shareLink).pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(new ShareLink.SaveSuccess(response.res));
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new ShareLink.SaveFail(e));
      }),
    );
  }

  @Action(ShareLink.SaveSuccess) saveShareLinkSuccess(
    ctx: StateContext<IAppStateModel>,
    action: ShareLink.SaveSuccess,
  ) {
    const shareLink = action.shareLink;
    const state = ctx.getState();
    const shareLinkState = { ...state.appState.shareLinkState };
    shareLinkState.isLoading = false;
    shareLinkState.shareLinks = shareLinkState.shareLinks.filter(
      (s) => s.linkUuid !== shareLink.linkUuid,
    );
    shareLinkState.shareLinks = [...shareLinkState.shareLinks, shareLink];
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          shareLinkState: patch<IShareLinkState>({
            ...shareLinkState,
          }),
        }),
      }),
    );
  }

  @Action(ShareLink.SaveFail) saveShareLinkFail(
    ctx: StateContext<IAppStateModel>,
    action: ShareLink.SaveFail,
  ) {
    const state = ctx.getState();
    const shareLinkState = state.appState.shareLinkState;
    shareLinkState.isLoading = false;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          shareLinkState: patch<IShareLinkState>({
            ...shareLinkState,
          }),
        }),
      }),
    );
    console.error(action.error);
  }

  @Action(ShareLink.Copy) copyShareLink(
    ctx: StateContext<IAppStateModel>,
    action: ShareLink.Copy,
  ) {
    const shareLinkId = action.shareLinkId;

    return this.ws.asyncRequest$('COPY_SHARE_LINK', shareLinkId).pipe(
      map((response) => {
        if (response.success) {
          return ctx.dispatch(new ShareLink.CopySuccess(response.res));
        } else {
          throw new Error(response.error);
        }
      }),
      catchError((e) => {
        return ctx.dispatch(new ShareLink.CopyFail(e));
      }),
    );
  }

  @Action(ShareLink.CopySuccess) copyShareLinkSuccess(
    ctx: StateContext<IAppStateModel>,
    action: ShareLink.CopySuccess,
  ) {
    const shareLink = action.shareLink;
    return shareLink;
  }

  @Action(ShareLink.CopyFail) copyShareLinkFail(
    ctx: StateContext<IAppStateModel>,
    action: ShareLink.CopyFail,
  ) {
    console.error(action.error);
  }

  @Action(ShareLink.Delete) deleteShareLink(
    ctx: StateContext<IAppStateModel>,
    action: ShareLink.Delete,
  ) {
    const shareLinkId = action.shareLinkId;

    return this.ws
      .asyncRequest$('DELETE_SHARE_LINK', { linkId: shareLinkId })
      .pipe(
        map((response) => {
          if (response.success) {
            return ctx.dispatch(new ShareLink.DeleteSuccess(shareLinkId));
          } else {
            throw new Error(response.error);
          }
        }),
        catchError((e) => {
          return ctx.dispatch(new ShareLink.DeleteFail(e));
        }),
      );
  }

  @Action(ShareLink.DeleteSuccess) deleteShareLinkSuccess(
    ctx: StateContext<IAppStateModel>,
    action: ShareLink.DeleteSuccess,
  ) {
    const shareLinkId = action.shareLinkId;
    const state = ctx.getState();
    const shareLinkState = { ...state.appState.shareLinkState };
    shareLinkState.isLoading = false;
    shareLinkState.shareLinks = shareLinkState.shareLinks.filter(
      (s) => s.linkUuid !== shareLinkId,
    );
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          shareLinkState: patch<IShareLinkState>({
            ...shareLinkState,
          }),
        }),
      }),
    );
  }

  @Action(ShareLink.DeleteFail) deleteShareLinkFail(
    ctx: StateContext<IAppStateModel>,
    action: ShareLink.DeleteFail,
  ) {
    const state = ctx.getState();
    const shareLinkState = state.appState.shareLinkState;
    shareLinkState.isLoading = false;
    ctx.setState(
      patch<IAppStateModel>({
        appState: patch<IAppState>({
          shareLinkState: patch<IShareLinkState>({
            ...shareLinkState,
          }),
        }),
      }),
    );
    console.error(action.error);
  }
}
